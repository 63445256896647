import {useIndividualFormElement} from 'hooks'
import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

interface NumberInputPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface NumberInputContentType {
  label: string
  description: string
  value: string
}

export const NumberInputPreview = ({
  id,
  type,
  isEditable,
}: NumberInputPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [numberInput, setNumberInput] = useState<NumberInputContentType>(
    individualElement?.content as NumberInputContentType,
  )

  const dispatch = useDispatch()

  const handleChangeInput = (event: any) => {
    event.preventDefault()

    setNumberInput((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, numberInput, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberInput])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div className="mt-4 h-[35px] flex w-[full] items-center border border-solid border-1 border-gray-300 rounded-sm overflow-hidden">
        <input
          type="number"
          name="value"
          value={numberInput?.value}
          onChange={(e: any) => {
            handleChangeInput(e)
          }}
          placeholder=""
          className={`text-black w-full h-full text-sm px-6`}
          disabled={
            // type !== 'web view' &&
            !individualElement?.editableBy.includes(userRole) || !isEditable
          }
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
