import {createActionSet} from '../../helpers'

//* AUTHENTICATION
export const LOGIN = createActionSet('LOGIN')
export const REGISTER = createActionSet('REGISTER')
export const LOGOUT = createActionSet('LOGOUT')
export const PASSWORD = createActionSet('PASSWORD')
export const USER = createActionSet('USER')
export const EDIT_USER_INFO = createActionSet('EDIT_USER_INFO')
export const RESET_PASSWORD = createActionSet('RESET_PASSWORD')
export const FORGET_PASSWORD = createActionSet('FORGET_PASSWORD')
export const CHANGE_PASSWORD = createActionSet('CHANGE_PASSWORD')
export const GET_APPUSER_LIST = createActionSet('GET_APPUSER_LIST')
export const GET_USER_LIST = createActionSet('GET_USER_LIST')
export const GET_COMPANY_USER_LIST = createActionSet('GET_COMPANY_USER_LIST')
export const UPDATE_USER_PROFILE_PIC = createActionSet(
  'UPDATE_USER_PROFILE_PIC',
)

export const UPDATE_PROFILE_PIC_OF_OTHER_USER = createActionSet(
  'UPDATE_PROFILE_PIC_OF_OTHER_USER',
)

// * DASHBOARD

//Get project list for dashboard view
export const GET_TO_PRICE_LIST_FOR_DASHBOARD = createActionSet(
  'GET_TO_PRICE_LIST_FOR_DASHBOARD',
)
export const GET_ACTIVE_LIST_FOR_DASHBOARD = createActionSet(
  'GET_ACTIVE_LIST_FOR_DASHBOARD',
)
export const GET_COMPELTED_LIST_FOR_DASHBOARD = createActionSet(
  'GET_COMPELTED_LIST_FOR_DASHBOARD',
)
export const GET_ALL_LIST_FOR_DASHBOARD = createActionSet(
  'GET_ALL_LIST_FOR_DASHBOARD',
)

// * GET overdue tickets of company
export const GET_OVERDUE_TICKET = createActionSet('GET_OVERDUE_TICKET')

// * GET Archived Project List
export const GET_ARCHIVED_PROJECT_LIST = createActionSet(
  'GET_ARCHIVED_PROJECT_LIST',
)

// * NOTIFICATIONS
export const GET_NOTIFICATIONS = createActionSet('GET_NOTIFICATIONS')
export const MARK_NOTIFICATION_AS_READ = createActionSet(
  'MARK_NOTIFICATION_AS_READ',
)
export const MARK_NOTIFICATION_AS_UNREAD = createActionSet(
  'MARK_NOTIFICATION_AS_UNREAD',
)
export const MARK_ALL_NOTIFICATION_AS_READ = createActionSet(
  'MARK_ALL_NOTIFICATION_AS_READ',
)

export const GET_ONLY_UNREAD_NOTIFICATION = createActionSet(
  'GET_ONLY_UNREAD_NOTIFICATION',
)

//* Company Users
export const CREATE_COMPANY_USER = createActionSet('CREATE_COMPANY_USER')
export const DEACTIVATE_USER = createActionSet('DEACTIVATE_USER')
export const ACTIVATE_COMPANY_USER = createActionSet('ACTIVATE_COMPANY_USER')
export const GET_COMPANY_USER_BY_ID = createActionSet('GET_COMPANY_USER_BY_ID')
export const UPDATE_COMPANY_USER = createActionSet('UPDATE_COMPANY_USER')
export const EDIT_COMPANY_USER_IMAGE = createActionSet(
  'EDIT_COMPANY_USER_IMAGE',
)
export const GET_COMPANY_DISABLED_USER = createActionSet(
  'GET_COMPANY_DISABLED_USER',
)

export const CREATE_USER_GROUP = createActionSet('CREATE_USER_GROUP')
export const DELETE_USER_GROUP = createActionSet('DELETE_COMPANY_USER_GROUP')
export const UPDATE_USER_GROUP_BASIC_DETAILS = createActionSet(
  'UPDATE_USER_GROUP_BASIC_DETAILS',
)
export const GET_COMPANY_USER_GROUP_BY_ID = createActionSet(
  'GET_COMPANY_USER_GROUP_BY_ID',
)
export const REMOVE_USER_FROM_GROUP = createActionSet('REMOVE_USER_FROM_GROUP')
export const GET_USER_GROUP_BY_ID = createActionSet('GET_USER_GROUP_BY_ID')
export const ADD_USER_TO_GROUP = createActionSet('ADD_USER_TO_GROUP')
export const GET_COMPANY_USER_GROUP_LIST = createActionSet(
  'GET_COMPANY_USER_GROUP_LIST',
)
export const GET_USER_TO_ADDED_IN_GROUP = createActionSet(
  'GET_USER_TO_ADDED_IN_GROUP',
)

//* CUSTOMER HANDLING
export const GET_CUSTOMER = createActionSet('GET_CUSTOMER')
export const GET_CUSTOMER_BY_ID = createActionSet('GET_CUSTOMER_BY_ID')
export const ADD_CUSTOMER = createActionSet('ADD_CUSTOMER')
export const UPDATE_CUSTOMER = createActionSet('UPDATE_CUSTOMER')

export const UPLOAD_CUSTOMER_FILES_AND_PHOTOS = createActionSet(
  'UPLOAD_CUSTOMER_FILES_AND_PHOTOS',
)
export const GET_CUSTOMER_FILES_AND_PHOTOS = createActionSet(
  'GET_CUSTOMER_FILES_AND_PHOTOS',
)

export const GET_ALL_INFO_CONTACTS = createActionSet('GET_ALL_INFO_CONTACTS')

//* SITE HANDLING
export const GET_SITE = createActionSet('GET_SITE')
export const GET_ARCHIVED_SITE = createActionSet('GET_ARCHIVED_SITE')
export const GET_SITE_BY_ID = createActionSet('GET_SITE_BY_ID')
export const ADD_SITE = createActionSet('ADD_SITE')
export const UPDATE_SITE = createActionSet('UPDATE_SITE')

export const GET_SITE_CONTACT = createActionSet('GET_SITE_CONTACT')
export const ADD_SITE_CONTACT = createActionSet('ADD_SITE_CONTACT')
export const UPDATE_SITE_CONTACT = createActionSet('UPDATE_SITE_CONTACT')
export const ALTER_SITE_CONTACT = createActionSet('ALTER_SITE_CONTACT')
export const ARCHIVE_SITE = createActionSet('ARCHIVE_SITE')
export const ACTIVATE_SITE = createActionSet('ACTIVATE_SITE')

export const UPLOAD_SITE_FILES = createActionSet('UPLOAD_SITE_FILES')
export const GET_SITE_FILES = createActionSet('GET_SITE_FILES')

//* SELECT OPTIONS HANDLING
export const GET_SELECT_OPTIONS = createActionSet('GET_SELECT_OPTIONS')

//* JOB
export const GET_JOB_LIST = createActionSet('GET_JOB_LIST')
export const ADD_JOB = createActionSet('ADD_JOB')
export const JOB_DETAIL_BY_ID = createActionSet('JOB_DETAIL_BY_ID')
export const UPDATE_JOB = createActionSet('UPDATE_JOB')

//* PROJECT
export const GET_PROJECT_LIST = createActionSet('GET_PROJECT_LIST')
export const ADD_PROJECT = createActionSet('ADD_PROJECT')
export const PROJECT_DETAIL_BY_ID = createActionSet('PROJECT_DETAIL_BY_ID')
export const UPDATE_PROJECT = createActionSet('UPDATE_PROJECT')
export const UPDATE_PROJECT_NUMBER_PREFIX = createActionSet(
  'UPDATE_PROJECT_NUMBER_PREFIX',
)
export const UPLOAD_PROJECT_IMAGE = createActionSet('UPLOAD_PROJECT_IMAGE')
export const DELETE_PROJECT_IMAGE = createActionSet('DELETE_PROJECT_IMAGE')

export const GET_PROJECT_LIST_BY_DEFINED_ID = createActionSet(
  'GET_PROJECT_LIST_BY_DEFINED_ID',
)

export const GET_SUPPLIER_LIST_OF_PURCHASE_ORDER = createActionSet(
  'GET_SUPPLIER_LIST_OF_PURCHASE_ORDER',
)

export const GET_PROJECT_PURCHASE_ORDER = createActionSet(
  'GET_PROJECT_PURCHASE_ORDER',
)

export const GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID = createActionSet(
  'GET_PROJECT_PURCHASE_ORDER_BY_SUPPLIER_ID',
)

export const DOWNLOAD_PURCHASE_ORDER = createActionSet(
  'DOWNLOAD_PURCHASE_ORDER',
)

export const UPLOAD_PROJECT_FILES_AND_PHOTOS = createActionSet(
  'UPLOAD_PROJECT_FILES_AND_PHOTOS',
)

export const GET_PROJECT_FILES_AND_PHOTOS = createActionSet(
  'GET_PROJECT_FILES_AND_PHOTOS',
)

export const GET_TICKET_FILES_AND_PHOTOS = createActionSet(
  'GET_TICKET_FILES_AND_PHOTOS',
)

export const GET_PROJECT_ASSETS_DROPDOWN_LIST = createActionSet(
  'GET_PROJECT_ASSETS_DROPDOWN_LIST',
)

// * PROJECT HISTORY
export const GET_PROJECT_HISTORY = createActionSet('GET_PROJECT_HISTORY')
export const GET_PROJECT_NOTE_BY_ID = createActionSet('GET_PROJECT_NOTE_BY_ID')
// * Variation
export const GET_PROJECT_VARIATION_LIST = createActionSet(
  'GET_PROJECT_VARIATION_LIST',
)

// Project User and User Group

export const GET_PROJECT_USER_LIST = createActionSet('GET_PROJECT_USER_LIST')
export const ADD_USER_TO_PROJECT = createActionSet('ADD_USER_TO_PROJECT')
export const REMOVE_PROJECT_USER = createActionSet('REMOVE_PROJECT_USER')
export const GET_COMPANY_USER_TO_ADDED_IN_PROJECT = createActionSet(
  'GET_COMPANY_USER_TO_ADDED_IN_PROJECT',
)

export const GET_PROJECT_USER_GROUP_LIST = createActionSet(
  'GET_PROJECT_USER_GROUP_LIST',
)
export const ADD_PROJECT_USER_GROUP = createActionSet('ADD_PROJECT_USER_GROUP')
export const REMOVE_PROJECT_USER_GROUP = createActionSet(
  'REMOVE_PROJECT_USER_GROUP',
)
export const GET_USER_GROUP_TO_ADDED_IN_PROJECT = createActionSet(
  'GET_USER_GROUP_TO_ADDED_IN_PROJECT',
)

export const GET_SELECTED_SITE_VISIT_TIMESHEET = createActionSet(
  'GET_SELECTED_SITE_VISIT_TIMESHEET',
)
export const UPDATE_COMPLETED_AT_OF_LABOUR_ENTRY = createActionSet(
  'UPDATE_COMPLETED_AT_OF_LABOUR_ENTRY',
)

export const GET_COMPANY_TIME_ENTRY = createActionSet('GET_COMPANY_TIME_ENTRY')

//* CHECKLIST HANDLING
export const GET_CHECKLIST = createActionSet('GET_CHECKLIST')
export const ADD_CHECKLIST = createActionSet('ADD_CHECKLIST')
export const UPDATE_CHECKLIST = createActionSet('UPDATE_CHECKLIST')
export const DELETE_CHECKLIST = createActionSet('DELETE_CHECKLIST')
export const ARCHIVE_CHECKLIST = createActionSet('ARCHIVE_CHECKLIST')
export const RESTORE_CHECKLIST = createActionSet('RESTORE_CHECKLIST')

//* JOB CHECKLIST HANDLING
export const GET_JOB_CHECKLIST = createActionSet('GET_JOB_CHECKLIST')
export const GET_JOB_CHECKLIST_BY_ID = createActionSet(
  'GET_JOB_CHECKLIST_BY_ID',
)
export const ADD_JOB_CHECKLIST = createActionSet('ADD_JOB_CHECKLIST')
export const UPDATE_JOB_CHECKLIST = createActionSet('UPDATE_JOB_CHECKLIST')
export const UPDATE_JOB_CHECKLIST_ITEM = createActionSet(
  'UPDATE_JOB_CHECKLIST_ITEM',
)

//* SITE_VISIT CHECKLIST HANDLING
export const GET_SITE_VISIT_CHECKLIST = createActionSet(
  'GET_SITE_VISIT_CHECKLIST',
)
export const ADD_SITE_VISIT_CHECKLIST = createActionSet(
  'ADD_SITE_VISIT_CHECKLIST',
)
export const UPDATE_SITE_VISIT_CHECKLIST = createActionSet(
  'UPDATE_SITE_VISIT_CHECKLIST',
)

//* GROUPS HANDLING
export const GET_GROUP = createActionSet('GET_GROUP')
export const ADD_GROUP = createActionSet('ADD_GROUP')
export const UPDATE_GROUP = createActionSet('UPDATE_GROUP')
export const DELETE_GROUP = createActionSet('DELETE_GROUP')

//* COMPANY DETAILS
export const GET_COMPANY_DETAILS = createActionSet('GET_COMPANY_DETAILS')
export const UPDATE_COMPANY_DETAILS = createActionSet('UPDATE_COMPANY_DETAILS')
export const UPDATE_COMPANY_NAME = createActionSet('UPDATE_COMPANY_NAME')
export const UPDATE_COMPANY_FINANCIAL_DETAILS = createActionSet(
  'UPDATE_COMPANY_FINANCIAL_DETAILS',
)

export const ADD_COMPANY_LOGO = createActionSet('UPDATE_COMPANY_LOGO')

// * COMPANY BANKING DETAILS
export const GET_COMPANY_BANK_DETAILS = createActionSet(
  'GET_COMPANY_BANK_DETAILS',
)
export const CREATE_COMPANY_BANK_DETAIL = createActionSet(
  'CREATE_COMPANY_BANK_DETAIL',
)
export const UPDATE_COMPANY_BANK_DETAIL = createActionSet(
  'UPDATE_COMPANY_BANK_DETAIL',
)
export const DELETE_COMPANY_BANK_DETAIL = createActionSet(
  'DELETE_COMPANY_BANK_DETAIL',
)

//* QUOTE SETTINGS
export const GET_QUOTE_SETTINGS = createActionSet('GET_QUOTE_SETTINGS')
export const UPDATE_QUOTE_SETTINGS = createActionSet('UPDATE_QUOTE_SETTINGS')

//* INVOICE SETTINGS
export const GET_INVOICE_SETTINGS = createActionSet('GET_INVOICE_SETTINGS')
export const UPDATE_INVOICE_SETTINGS = createActionSet(
  'UPDATE_INVOICE_SETTINGS',
)

//* FILES AND PHOTOS
export const GET_FILES_AND_PHOTOS = createActionSet('GET_FILES_AND_PHOTOS')
export const ADD_FILES_AND_PHOTOS = createActionSet('ADD_FILES_AND_PHOTOS')

export const DOWNLOAD_PURCHASE_ORDER_SLIP = createActionSet(
  'DOWNLOAD_PURCHASE_ORDER_SLIP',
)

export const DOWNLOAD_PROJECT_PRELIMINARY = createActionSet(
  'DOWNLOAD_PROJECT_PRELIMINARY',
)

export const DOWNLOAD_PDF_COMMON = createActionSet('DOWNLOAD_PDF_COMMON')

export const DOWNLOAD_ANDROID_APK = createActionSet('DOWNLOAD_ANDROID_APK')

//* PRICE BOOKS
export const GET_PRICE_BOOKS = createActionSet('GET_PRICE_BOOKS')
export const ADD_PRICE_BOOKS = createActionSet('ADD_PRICE_BOOKS')
export const DELETE_PRICE_BOOKS = createActionSet('DELETE_PRICE_BOOKS')
export const UPDATE_PRICE_BOOKS = createActionSet('UPDATE_PRICE_BOOKS')
export const SEARCH_PRICE_BOOKS = createActionSet('SEARCH_PRICE_BOOKS')

//* SALES ACCOUNT CODES
export const GET_SALES_ACCOUNT_CODES = createActionSet(
  'GET_SALES_ACCOUNT_CODES',
)
export const ADD_SALES_ACCOUNT_CODES = createActionSet(
  'ADD_SALES_ACCOUNT_CODES',
)
export const DELETE_SALES_ACCOUNT_CODES = createActionSet(
  'DELETE_SALES_ACCOUNT_CODES',
)
export const UPDATE_SALES_ACCOUNT_CODES = createActionSet(
  'UPDATE_SALES_ACCOUNT_CODES',
)

// SUPPLIER SETTING

export const CREATE_SUPPLIER_SETTING = createActionSet(
  'CREATE_SUPPLIER_SETTING',
)
export const GET_SUPPLIER_SETTING = createActionSet('GET_SUPPLIER_SETTING')
export const UPDATE_SUPPLIER_SETTING = createActionSet(
  'UPDATE_SUPPLIER_SETTING',
)

// LABOUR ELEMENT SETTING

export const GET_LABOUR_ELEMENT_UNITS = createActionSet(
  'GET_LABOUR_ELEMENT_UNITS',
)

export const CREATE_LABOUR_ELEMENT_SETTING = createActionSet(
  'CREATE_LABOUR_ELEMENT_SETTING',
)
export const GET_LABOUR_ELEMENT_SETTING = createActionSet(
  'GET_LABOUR_ELEMENT_SETTING',
)

export const UPDATE_LABOUR_ELEMENT_SETTING = createActionSet(
  'GET_LABOUR_ELEMENT_SETTING',
)

export const GET_LABOUR_ELEMENT_FOR_SELECT_FIELD = createActionSet(
  'GET_LABOUR_ELEMENT_FOR_SELECT_FIELD',
)

export const UPLOAD_LABOUR_ELEMENT_FILE = createActionSet(
  'UPLOAD_LABOUR_ELEMENT_FILE',
)

// LABOUR ELEMENT GROUP SETTING

export const CREATE_LABOUR_ELEMENT_GROUP = createActionSet(
  'CREATE_LABOUR_ELEMENT_GROUP',
)
export const UPDATE_LABOUR_ELEMENT_GROUP = createActionSet(
  'UPDATE_LABOUR_ELEMENT_GROUP',
)
export const GET_LABOUR_ELEMENT_GROUP = createActionSet(
  'GET_LABOUR_ELEMENT_GROUP',
)

export const ATTACH_LABOUR_ELEMENT_TO_GROUP = createActionSet(
  'ATTACH_LABOUR_ELEMENT_TO_GROUP',
)

export const REMOVE_LABOUR_ELEMENT_FROM_GROUP = createActionSet(
  'REMOVE_LABOUR_ELEMENT_FROM_GROUP',
)

export const GET_LABOUR_GROUP_BY_ID = createActionSet('GET_LABOUR_GROUP_BY_ID')

//MARK: MATERIAL ELEMENT

export const GET_MATERIAL_ELEMENT_UNIT_LIST = createActionSet(
  'GET_MATERIAL_ELEMENT_UNIT_LIST',
)

export const GET_MATERIAL_UNIT_LIST = createActionSet('GET_MATERIAL_UNIT_LIST')

export const CREATE_MATERIAL_ELEMENT_SETTING = createActionSet(
  'CREATE_MATERIAL_ELEMENT_SETTING',
)
export const GET_MATERIAL_ELEMENT_SETTING = createActionSet(
  'GET_MATERIAL_ELEMENT_SETTING',
)

export const UPDATE_MATERIAL_ELEMENT_SETTING = createActionSet(
  'GET_MATERIAL_ELEMENT_SETTING',
)

export const DELETE_MATERIAL_ELEMENT_SETTING = createActionSet(
  'DELETE_MATERIAL_ELEMENT_SETTING',
)

export const GET_MATERIAL_ELEMENT_FOR_SELECT_FIELD = createActionSet(
  'GET_MATERIAL_ELEMENT_FOR_SELECT_FIELD',
)

//MARK: MATERIAL ELEMENT GROUP

export const CREATE_MATERIAL_ELEMENT_GROUP = createActionSet(
  'CREATE_MATERIAL_ELEMENT_GROUP',
)
export const UPDATE_MATERIAL_ELEMENT_GROUP = createActionSet(
  'UPDATE_MATERIAL_ELEMENT_GROUP',
)
export const GET_MATERIAL_ELEMENT_GROUP = createActionSet(
  'GET_MATERIAL_ELEMENT_GROUP',
)

export const ATTACH_MATERIAL_ELEMENT_TO_GROUP = createActionSet(
  'ATTACH_MATERIAL_ELEMENT_TO_GROUP',
)

export const REMOVE_MATERIAL_ELEMENT_FROM_GROUP = createActionSet(
  'REMOVE_MATERIAL_ELEMENT_FROM_GROUP',
)

export const GET_MATERIAL_GROUP_BY_ID = createActionSet(
  'GET_MATERIAL_GROUP_BY_ID',
)

export const DELETE_MATERIAL_ELEMENT_GROUP = createActionSet(
  'DELETE_MATERIAL_ELEMENT_GROUP',
)

export const GET_MATERIAL_ELEMENT_AND_GROUP_LIST = createActionSet(
  'GET_MATERIAL_ELEMENT_AND_GROUP_LIST',
)

export const UPLOAD_MATERIAL_ELEMENT_FILE = createActionSet(
  'UPLOAD_MATERIAL_ELEMENT_FILE',
)

// PRELIMINARY TEMPLATES

export const CREATE_PRELIMINARY_TEMPLATES = createActionSet(
  'CREATE_PRELIMINARY_TEMPLATES',
)

export const GET_PRELIMINARY_TEMPLATE_LIST = createActionSet(
  'GET_PRELIMINARY_TEMPLATE_LIST',
)

export const GENERATE_PROJECT_PRELIMINARY_FROM_TEMPLATE = createActionSet(
  'GENERATE_PROJECT_PRELIMINARY_FROM_TEMPLATE',
)

export const GET_PRELIMINARY_TEMPLATE_BY_ID = createActionSet(
  'GET_PRELIMINARY_TEMPLATE_BY_ID',
)

export const UPDATE_PRELIMINARY_TEMPLATE = createActionSet(
  'UPDATE_PRELIMINARY_TEMPLATE',
)

// preliminary template item

export const CREATE_PRELIMINARY_ITEM_TEMPLATE = createActionSet(
  'CREATE_PRELIMINARY_ITEM_TEMPLATE',
)

export const UPDATE_PRELIMINARY_ITEM_TEMPLATES = createActionSet(
  'UPDATE_PRELIMINARY_ITEM_TEMPLATES',
)

export const UPDATE_PRELIMINARY_ITEM_MANDATORY_INFO = createActionSet(
  'UPDATE_PRELIMINARY_ITEM_MANDATORY_INFO',
)

export const DELETE_PRELIMINARY_ITEM_TEMPLATE = createActionSet(
  'DELETE_PRELIMINARY_ITEM_TEMPLATE',
)
export const DELETE_PRELIMINARY_TEMPLATE = createActionSet(
  'DELETE_PRELIMINARY_TEMPLATE',
)

//preliminary template line item

export const CREATE_PRELIMINARY_LINE_ITEM_TEMPLATE = createActionSet(
  'CREATE_PRELIMINARY_LINE_ITEM_TEMPLATE',
)

export const UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE = createActionSet(
  'UPDATE_PRELIMINARY_LINE_ITEM_TEMPLATE',
)

export const DELETE_PRELIMINARY_LINE_ITEM_TEMPLATE = createActionSet(
  'DELETE_PRELIMINARY_LINE_ITEM_TEMPLATE',
)

//FORM BUILDER SETTING
export const REMOVE_ASSET_IDS = createActionSet('REMOVE_ASSET_IDS')
export const UPDATE_FORM_FROM_API = createActionSet('UPDATE_FORM_FROM_API')
export const UPDATE_FORM_LAYOUTS = createActionSet('UPDATE_FORM_LAYOUTS')
export const ON_ADD_FORM_SECTIONS = createActionSet('ON_ADD_FORM_SECTIONS')

export const ALTER_FORM_BUILDER_CONTENT = createActionSet(
  'ALTER_FORM_BUILDER_CONTENT',
)

export const ALTER_FORM_BUILDER_SETTINGS = createActionSet(
  'ALTER_FORM_BUILDER_SETTINGS',
)

export const DELETE_FORM_ELEMENT = createActionSet('DELETE_FORM_ELEMENT')

export const ON_SELECT_FORM_SECTION = createActionSet('ON_SELECT_FORM_SECTION')

//* QUOTE
export const GET_QUOTE_ITEMS = createActionSet('GET_QUOTE_ITEMS')
export const GET_QUOTES = createActionSet('GET_QUOTES')
export const GET_QUOTE_DETAILS = createActionSet('GET_QUOTE_DETAILS')
export const GET_QUOTE_OVERVIEW = createActionSet('GET_QUOTE_OVERVIEW')
export const ADD_NEW_QUOTE = createActionSet('ADD_NEW_QUOTE')
export const ADD_NEW_QUOTE_ITEM = createActionSet('ADD_NEW_QUOTE_ITEM')
export const DELETE_QUOTE_ITEM = createActionSet('DELETE_QUOTE_ITEM')

export const ADD_NEW_LINE_ITEM = createActionSet('ADD_NEW_LINE_ITEM')
export const ADD_NEW_LINE_ITEM_MANUAL = createActionSet(
  'ADD_NEW_LINE_ITEM_MANUAL',
)
export const DELETE_LINE_ITEM = createActionSet('DELETE_LINE_ITEM')
export const UPDATE_LINE_ITEM = createActionSet('UPDATE_LINE_ITEM')
export const VOID_QUOTE = createActionSet('VOID_QUOTE')

export const PUBLISH_QUOTE = createActionSet('PUBLISH_QUOTE')
export const MARK_AS_SENT_QUOTE = createActionSet('MARK_AS_SENT_QUOTE')
export const PUBLISH_AND_MARK_AS_SENT_QUOTE = createActionSet(
  'PUBLISH_AND_MARK_AS_SENT_QUOTE',
)
export const ACCEPT_QUOTE = createActionSet('ACCEPT_QUOTE')
export const DECLINE_QUOTE = createActionSet('DECLINE_QUOTE')

export const UNACCEPT_QUOTE = createActionSet('UNACCEPT_QUOTE')
export const UNDECLINE_QUOTE = createActionSet('UNDECLINE_QUOTE')

export const GET_ACCEPTED_QUOTE_LINE_ITEM = createActionSet(
  'GET_ACCEPTED_QUOTE_LINE_ITEM',
)

export const GET_LINE_ITEM_LABOUR_ELEMENTS = createActionSet(
  'GET_LINE_ITEM_LABOUR_ELEMENTS',
)

export const GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT = createActionSet(
  'GET_MATERIAL_PURCHASE_ORDER_OF_PROJECT',
)

// PRELIMINARY
export const GET_PRELIMINARY_LIST = createActionSet('GET_PRELIMINARY_LIST')

// PRELIMINARY ITEM
export const CREATE_PRELIMINARY_ITEM = createActionSet(
  'CREATE_PRELIMINARY_ITEM',
)
export const UPDATE_PRELIMINARY_ITEM_INFO = createActionSet(
  'UPDATE_PRELIMINARY_ITEM_INFO',
)
export const UPDATE_PRELIMINARY_ITEM_MONITORY_INFO = createActionSet(
  'UPDATE_PRELIMINARY_ITEM_MONITORY_INFO',
)
export const DELETE_PRELIMINARY_ITEM = createActionSet(
  'DELETE_PRELIMINARY_ITEM',
)

// PRELIMINARY LINE ITEM
export const CREATE_PRELIMINARY_LINE_ITEM = createActionSet(
  'CREATE_PRELIMINARY_LINE_ITEM',
)
export const UPDATE_PRELIMINARY_LINE_ITEM = createActionSet(
  'UPDATE_PRELIMINARY_LINE_ITEM',
)
export const UPDATE_PROJECT_PRELIMINARY_DETAILS = createActionSet(
  'UPDATE_PROJECT_PRELIMINARY_DETAILS',
)

export const DELETE_PRELIMINARY_LINE_ITEM = createActionSet(
  'DELETE_PRELIMINARY_LINE_ITEM',
)

export const DELETE_PRELIMINARY_ITEMS = createActionSet(
  'DELETE_PRELIMINARY_ITEMS',
)

//* INVOICE
export const GENERATE_SITE_VISIT_INVOICES = createActionSet(
  'GENERATE_SITE_VISIT_INVOICES',
)

export const GENERATE_SELECTED_TICKETS_INVOICE = createActionSet(
  'GENERATE_SELECTED_TICKETS_INVOICE',
)

export const GET_INVOICE_ITEMS = createActionSet('GET_INVOICE_ITEMS')
export const GET_INVOICES = createActionSet('GET_INVOICES')
export const GET_INVOICE_BY_ID = createActionSet('GET_INVOICE_BY_ID')
export const CREATE_MANUAL_INVOICE = createActionSet('CREATE_MANUAL_INVOICE')
export const ADD_NEW_INVOICE = createActionSet('ADD_NEW_INVOICE')
export const ADD_NEW_INVOICE_ITEM = createActionSet('ADD_NEW_INVOICE_ITEM')
export const UPDATE_INVOICE_ITEM = createActionSet('UPDATE_INVOICE_ITEM')
export const DELETE_INVOICE_ITEM = createActionSet('DELETE_INVOICE_ITEM')

export const ADD_NEW_LINE_ITEM_INVOICE = createActionSet(
  'ADD_NEW_LINE_ITEM_INVOICE',
)
export const DELETE_LINE_ITEM_INVOICE = createActionSet(
  'DELETE_LINE_ITEM_INVOICE',
)
export const UPDATE_LINE_ITEM_INVOICE = createActionSet(
  'UPDATE_LINE_ITEM_INVOICE',
)

export const CREATE_INVOICE_MANUAL_LINE_ITEM = createActionSet(
  'CREATE_INVOICE_MANUAL_LINE_ITEM',
)

export const UPDATE_INVOICE_MANUAL_LINE_ITEM = createActionSet(
  'UPDATE_INVOICE_MANUAL_LINE_ITEM',
)

export const APPROVE_AUTOMATIC_INVOICE = createActionSet(
  'APPROVE_AUTOMATIC_INVOICE',
)
export const CHANGE_AUTOMATIC_INVOICE_STATUS_TO_PAID = createActionSet(
  'CHANGE_AUTOMATIC_INVOICE_STATUS_TO_PAID',
)

export const CHANGE_INVOICE_STATUS_TO_UNPAID = createActionSet(
  'CHANGE_INVOICE_STATUS_TO_UNPAID',
)

export const CHANGE_INVOICE_STATUS_TO_DISPUTE = createActionSet(
  'CHANGE_INVOICE_STATUS_TO_DISPUTE',
)

export const CHANGE_INVOICE_STATUS_TO_UNDISPUTE = createActionSet(
  'CHANGE_INVOICE_STATUS_TO_UNDISPUTE',
)

export const CHANGE_INVOICE_STATUS_TO_VOID = createActionSet(
  'CHANGE_INVOICE_STATUS_TO_VOID',
)

export const CHANGE_MANUAL_INVOICE_STATUS_TO_PAID = createActionSet(
  'CHANGE_MANUAL_INVOICE_STATUS_TO_PAID',
)

export const CREATE_INVOICE_ITEMS_WITH_TICKETS = createActionSet(
  'CREATE_INVOICE_ITEMS_WITH_TICKETS',
)

export const ADD_INVOICE_MANUAL_LABOUR_ENTRY = createActionSet(
  'ADD_INVOICE_MANUAL_LABOUR_ENTRY',
)

//MARK: Sub-contractor invoice

export const CREATE_INVOICE_WITH_SITE_VISITS = createActionSet(
  'CREATE_INVOICE_WITH_SITE_VISITS',
)

//? WORKER INVOICE
export const GET_WORKER_PROJECT_LABOUR_ENTRIES = createActionSet(
  'GET_WORKER_PROJECT_LABOUR_ENTRIES',
)

export const GET_LABOUR_ENTRY_BY_TICKET_ID = createActionSet(
  'GET_LABOUR_ENTRY_BY_TICKET_ID',
)

export const GENERATE_WORKER_INVOICE = createActionSet(
  'GENERATE_WORKER_INVOICE',
)

export const GET_WORKER_INVOICE_BY_ID = createActionSet(
  'GET_WORKER_INVOICE_BY_ID',
)

export const GET_WORKER_INVOICE_LIST_OF_COMPANY = createActionSet(
  'GET_WORKER_INVOICE_LIST_OF_COMPANY',
)

export const UPDATE_WORKER_INVOICE_BASIC_DETAILS = createActionSet(
  'UPDATE_WORKER_INVOICE_BASIC_DETAILS',
)

export const APPROVE_WORKER_INVOICE = createActionSet('APPROVE_WORKER_INVOICE')

export const MAKE_WORKER_INVOICE_STATUS_TO_PAID = createActionSet(
  'MAKE_WORKER_INVOICE_STATUS_TO_PAID',
)

export const CREATE_WORKER_INVOICE_ITEM = createActionSet(
  'CREATE_WORKER_INVOICE_ITEM',
)

export const UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM = createActionSet(
  'UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_ITEM',
)

export const DELETE_WORKER_INVOICE_ITEM = createActionSet(
  'DELETE_WORKER_INVOICE_ITEM',
)

export const GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE = createActionSet(
  'GET_PROJECT_LIST_NOT_INCLUDED_IN_WORKER_INVOICE',
)

export const GET_INVOICABLE_PROJECT_LABOUR_ENTRIES_OF_PROJECT = createActionSet(
  'GET_INVOICABLE_PROJECT_LABOUR_ENTRIES_OF_PROJECT',
)

//worker invoice line item

export const CREATE_WORKER_INVOICE_LINE_ITEM = createActionSet(
  'CREATE_WORKER_INVOICE_LINE_ITEM',
)

export const CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM = createActionSet(
  'CREATE_WORKER_INVOICE_MANUAL_LINE_ITEM',
)

export const UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_LINE_ITEM = createActionSet(
  'UPDATE_BASIC_DETAILS_OF_WORKER_INVOICE_LINE_ITEM',
)

export const ADD_REMARKS_INVOICE = createActionSet('ADD_REMARKS_INVOICE')

export const ADD_REMARK_TO_WORKER_INVOICE = createActionSet(
  'ADD_REMARK_TO_WORKER_INVOICE',
)

export const GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE = createActionSet(
  'GET_PROJECT_LIST_OF_USER_NOT_INCLUDED_IN_INVOICE',
)

export const GET_INVOICABLE_PROJECT_LABOUR_ENTRIES = createActionSet(
  'GET_INVOICABLE_PROJECT_LABOUR_ENTRIES',
)

export const GET_SITE_VISIT_LIST_WITH_TICKETS = createActionSet(
  'GET_SITE_VISIT_LIST_WITH_TICKETS',
)

export const GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE = createActionSet(
  'GET_SITE_VISITS_NOT_PRESENT_IN_INVOICE',
)

export const GET_INVOICABLE_TICKETS_OF_SITE_VISIT = createActionSet(
  'GET_INVOICABLE_TICKETS_OF_SITE_VISIT',
)

export const GET_UNASSIGNED_TICKETS_OF_PROJECTS = createActionSet(
  'GET_UNASSIGNED_TICKETS_OF_PROJECTS',
)

export const ADD_INVOICE_LINE_ITEM_WITH_TICKETS = createActionSet(
  'ADD_INVOICE_LINE_ITEM_WITH_TICKETS',
)

export const ADD_INVOICE_ITEM_WITH_TICKETS = createActionSet(
  'ADD_INVOICE_ITEM_WITH_TICKETS',
)

export const APPROVE_MANUAL_INVOICE = createActionSet('APPROVE_MANUAL_INVOICE')
export const ADD_COST_TO_INVOICE = createActionSet('ADD_COST_TO_INVOICE')
export const MARK_AS_SENT_INVOICE = createActionSet('MARK_AS_SENT_INVOICE')
export const VOID_INVOICE = createActionSet('VOID_INVOICE')

//* SITE VISITS
export const GET_SITE_VISIT_LIST = createActionSet('GET_SITE_VISIT_LIST')
export const GET_SITE_VISIT_TODAY_EVENTS_LIST = createActionSet(
  'GET_SITE_VISIT_TODAY_EVENTS_LIST',
)
export const GET_TIME_ENTRY_TODAY_EVENTS_LIST = createActionSet(
  'GET_TIME_ENTRY_TODAY_EVENTS_LIST',
)
export const GET_VOIDED_SITE_VISIT_LIST = createActionSet(
  'GET_VOIDED_SITE_VISIT_LIST',
)
export const MARK_AS_READY_TO_INVOICE = createActionSet(
  'MARK_AS_READY_TO_INVOICE',
)
export const MANUALLY_MARK_AS_INVOICED = createActionSet(
  'MANUALLY_MARK_AS_INVOICED',
)
export const GET_SITE_VISIT_DETAIL = createActionSet('GET_SITE_VISIT_DETAIL')
export const CREATE_SITE_VISIT_LIST = createActionSet('CREATE_SITE_VISIT_LIST')
export const UPDATE_SITE_VISIT_BASIC_DETAILS = createActionSet(
  'UPDATE_SITE_VISIT_BASIC_DETAILS',
)
export const ASSIGN_SITE_EMPLOYEE = createActionSet('ASSIGN_SITE_EMPLOYEE')
export const UNASSIGN_SITE_EMPLOYEE = createActionSet('UNASSIGN_SITE_EMPLOYEE')
export const GET_ASSIGNED_SITE_EMPLOYEE_LIST = createActionSet(
  'GET_ASSIGNED_SITE_EMPLOYEE_LIST',
)
export const VOID_SITE_VISIT = createActionSet('VOID_SITE_VISIT')

//* SCHEDULING AND EVENT
export const CREATE_EVENT = createActionSet('CREATE_EVENT')

//* LABOUR AND TIME ENTRY
export const CREATE_TIME_ENTRY = createActionSet('CREATE_TIME_ENTRY')
export const GET_TIME_ENTRY = createActionSet('GET_TIME_ENTRY')
export const UPDATE_TIME_ENTRY = createActionSet('UPDATE_TIME_ENTRY')
export const DELETE_TIME_ENTRY = createActionSet('DELETE_TIME_ENTRY')
export const LABOUR_COMPLETE = createActionSet('LABOUR_COMPLETE')
export const LABOUR_NEEDS_TO_RETURN = createActionSet('LABOUR_NEEDS_TO_RETURN')

//* SIGN OFF
export const SIGNED_OFF_LIST = createActionSet('SIGNED_OFF_LIST')

//* INVOICING
export const GET_INVOICE = createActionSet('GET_INVOICE')
export const UPDATE_INVOICE = createActionSet('UPDATE_INVOICE')
export const DELETE_INVOICE = createActionSet('DELETE_INVOICE')

//* NOTES AND HISTORY
export const GET_NOTES = createActionSet('GET_NOTES')
export const GET_JOB_NOTES = createActionSet('GET_JOB_NOTES')
export const GET_CUSTOMER_NOTES = createActionSet('GET_CUSTOMER_NOTES')
export const CREATE_CUSTOMER_NOTES = createActionSet('CREATE_CUSTOMER_NOTES')
export const UPDATE_CUSTOMER_NOTES = createActionSet('UPDATE_CUSTOMER_NOTES')
export const DELETE_CUSTOMER_NOTES = createActionSet('DELETE_CUSTOMER_NOTES')
export const GET_SITE_NOTES = createActionSet('GET_SITE_NOTES')
export const CREATE_SITE_NOTES = createActionSet('CREATE_SITE_NOTES')
export const UPDATE_SITE_NOTES = createActionSet('UPDATE_SITE_NOTES')
export const DELETE_SITE_NOTES = createActionSet('DELETE_SITE_NOTES')
export const GET_SITE_VISIT_NOTES = createActionSet('GET_SITE_VISIT_NOTES')
export const GET_NOTES_HOSTORY_LIST = createActionSet('GET_NOTES_HOSTORY_LIST')
export const ADD_NOTES_ENTRY = createActionSet('ADD_NOTES_ENTRY')

export const CREATE_PROJECT_NOTES = createActionSet('CREATE_PROJECT_NOTES')
export const UPDATE_PROJECT_NOTES = createActionSet('UPDATE_PROJECT_NOTES')
export const GET_PROJECT_NOTES = createActionSet('GET_PROJECT_NOTES')
export const DELETE_PROJECT_NOTES = createActionSet('DELETE_PROJECT_NOTES')

//* FINANCIAL SUMMARY
export const FINANCE_SUMMARY = createActionSet('FINANCE_SUMMARY')
export const USERS_FINANCIAL_DETAILS = createActionSet(
  'USERS_FINANCIAL_DETAILS',
)
export const SITE_VISIT_FINANCIAL_BREAKDOWN = createActionSet(
  'SITE_VISIT_FINANCIAL_BREAKDOWN',
)

//* PROJECT PROGRESS

export const BASIC_PROJECT_PROGRESS_DETAILS = createActionSet(
  'BASIC_PROJECT_PROGRESS_DETAILS',
)
export const PROJECT_DATE_CALCULATOR = createActionSet(
  'PROJECT_DATE_CALCULATOR',
)
export const CUSTOM_PROJECT_DATE_CALCULATOR = createActionSet(
  'CUSTOM_PROJECT_DATE_CALCULATOR',
)
export const PROJECT_STATUS = createActionSet('PROJECT_STATUS')

//* REPORTS

export const GENERATE_PROJECT_REPORTS = createActionSet(
  'GENERATE_PROJECT_REPORTS',
)

export const COST_REPORT = createActionSet('COST_REPORT')

//* STATUS BOARD
export const STATUS_BOARD = createActionSet('STATUS_BOARD')

// PLAN VIEWER
export const ADD_PROJECT_PLAN = createActionSet('ADD_PROJECT_PLAN')
export const UPDATE_PROJECT_PLAN = createActionSet('UPDATE_PROJECT_PLAN')
export const DELETE_PROJECT_PLAN = createActionSet('DELETE_PROJECT_PLAN')
export const GET_PROJECT_PLAN_LIST = createActionSet('GET_PROJECT_PLAN_LIST')

export const GET_LINKED_CHECKLISTS = createActionSet('GET_LINKED_CHECKLISTS')
export const GET_UNLINKED_CHECKLISTS = createActionSet(
  'GET_UNLINKED_CHECKLISTS',
)
export const LINK_CHECKLIST = createActionSet('LINK_CHECKLIST')
export const UNLINK_CHECKLIST = createActionSet('UNLINK_CHECKLIST')
export const UPLOAD_FLOOR_PLAN = createActionSet('UPLOAD_FLOOR_PLAN')

// WEB VIEW
export const GET_ALL_SITEVISITS_WEBVIEW = createActionSet(
  'GET_ALL_SITEVISITS_WEBVIEW',
)

//ITEM TYPE

export const CREATE_ITEM_TYPE = createActionSet('CREATE_ITEM_TYPE')
export const UPDATE_ITEM_TYPE = createActionSet('UPDATE_ITEM_TYPE')
export const CLEAR_ITEM_TYPE = createActionSet('CLEAR_ITEM_TYPE')
export const DELETE_ITEM_TYPE = createActionSet('DELETE_ITEM_TYPE')
export const GET_ITEM_TYPE_LIST = createActionSet('GET_ITEM_TYPE_LIST')
export const GET_ITEM_TYPE_BY_ID = createActionSet('GET_ITEM_TYPE_BY_ID')

//ITEM CATEGORIES

export const CREATE_ITEM_CATEGORY = createActionSet('CREATE_ITEM_CATEGORY')
export const GET_ITEM_CATEGORY_LIST = createActionSet('GET_ITEM_CATEGORY_LIST')
export const UPDATE_ITEM_CATEGORY = createActionSet('UPDATE_ITEM_CATEGORY')
export const DELETE_ITEM_CATEGORY = createActionSet('DELETE_ITEM_CATEGORY')

export const GET_ITEM_LIST = createActionSet('GET_ITEM_LIST')
export const CREATE_ITEM = createActionSet('CREATE_ITEM')
export const UPDATE_ITEM = createActionSet('UPDATE_ITEM')
export const DELETE_ITEM = createActionSet('DELETE_ITEM')

export const GET_ITEM_LIST_BY_SUPPLIER_ID = createActionSet(
  'GET_ITEM_LIST_BY_SUPPLIER_ID',
)

export const GET_LABOUR_ELEMENT_AND_GROUP_LIST = createActionSet(
  'GET_LABOUR_ELEMENT_AND_GROUP_LIST',
)

//forms

export const CREATE_FORM = createActionSet('CREATE_FORM')
export const GET_FORM_BY_ID = createActionSet('GET_FORM_BY_ID')
export const GET_FORM_FIELD_DATA_TYPE = createActionSet(
  'GET_FORM_FIELD_DATA_TYPE',
)
export const GET_FORM_LIST = createActionSet('GET_FORM_LIST')

export const UPDATE_FORM_FIELD = createActionSet('UPDATE_FORM_FIELD')
export const BULK_UPDATE_FORM_FIELDS_ORDER = createActionSet(
  'BULK_UPDATE_FORM_FIELDS_ORDER',
)
export const PUBLISH_FORM = createActionSet('PUBLISH_FORM')

export const DEACTIVATE_FORM = createActionSet('DEACTIVATE_FORM')
export const ACTIVATE_FORM = createActionSet('ACTIVATE_FORM')

export const GET_PROJECT_LIST_BY_FORM_ID = createActionSet(
  'GET_PROJECT_LIST_BY_FORM_ID',
)

export const GET_UNATTACHED_FORM_BY_PROJECT_ID = createActionSet(
  'GET_UNATTACHED_FORM_BY_PROJECT_ID',
)

// project forms

export const CREATE_PROJECT_FORM = createActionSet('CREATE_PROJECT_FORM')
export const GET_PROJECT_FORM_BY_ID = createActionSet('GET_PROJECT_FORM_BY_ID')
export const GET_PROJECT_DETAILS_BY_PROJECTFORMID = createActionSet(
  'GET_PROJECT_DETAILS_BY_PROJECTFORMID',
)

export const UPDATE_PROJECT_MANDATORIES = createActionSet(
  'UPDATE_PROJECT_MANDATORIES',
)

export const DELETE_PROJECT_FORM = createActionSet('DELETE_PROJECT_FORM')

export const ADD_FORM_ASSETS = createActionSet('ADD_FORM_ASSETS')
export const REMOVE_FORM_ASSETS = createActionSet('REMOVE_FORM_ASSETS')

export const ADD_TICKET_FORM_FIELDS_ATTACHMENTS = createActionSet(
  'ADD_TICKET_FORM_FIELDS_ATTACHMENTS',
)

export const REMOVE_TICKET_FORM_FIELDS_ATTACHMENTS = createActionSet(
  'REMOVE_TICKET_FORM_FIELDS_ATTACHMENTS',
)

//Tickets

export const CREATE_TICKETS = createActionSet('CREATE_TICKETS')
export const GET_SITE_VISIT_TICKETLIST = createActionSet(
  'GET_SITE_VISIT_TICKETLIST',
)

export const GET_TICKET_LIST_BY_PROJECTPLAN_ID = createActionSet(
  'GET_TICKET_LIST_BY_PROJECT_ID',
)

export const GET_TICKET_DETAILS_BY_ID = createActionSet(
  'GET_TICKET_DETAILS_BY_ID',
)

export const UPDATE_TICKET = createActionSet('UPDATE_TICKET')
export const ADD_ATTACHMENTS_TO_TICKET = createActionSet(
  'ADD_ATTACHMENTS_TO_TICKET',
)

export const REMOVE_TICKET_ATTACHMENTS = createActionSet(
  'REMOVE_TICKET_ATTACHMENTS',
)
export const REMOVE_TICKET = createActionSet('REMOVE_TICKET')

export const GET_TICKET_ATTACHMENT_LIST = createActionSet(
  'GET_TICKET_ATTACHMENT_LIST',
)

export const GET_PROJECT_TICKET_LIST = createActionSet(
  'GET_PROJECT_TICKET_LIST',
)
export const ADD_ATTACHMENT_TO_LABOUR_ELEMENT = createActionSet(
  'ADD_ATTACHMENT_TO_LABOUR_ELEMENT',
)

export const REMOVE_ATTACHMENTS_LABOUR_ELEMENT = createActionSet(
  'REMOVE_ATTACHMENTS_LABOUR_ELEMENT',
)

export const MAKE_LABOUR_ELEMENT_TO_COMPLETE = createActionSet(
  'MAKE_LABOUR_ELEMENT_TO_COMPLETE',
)

export const MAKE_LABOUR_ELEMENT_TO_INCOMPLETE = createActionSet(
  'MAKE_LABOUR_ELEMENT_TO_INCOMPLETE',
)

export const MAKE_LABOUR_ELEMENT_MANDATORY = createActionSet(
  'MAKE_LABOUR_ELEMENT_MANDATORY',
)

export const REMOVE_LABOUR_ELEMENT_MANDATORY_STATUS = createActionSet(
  'REMOVE_LABOUR_ELEMENT_MANDATORY_STATUS',
)

export const ADD_TICKET_FORM_FIELD_ATACHMENT = createActionSet(
  'ADD_TICKET_FORM_FIELD_ATACHMENT',
)

export const REMOVE_TICKET_FROM_FORM_FIELD = createActionSet(
  'REMOVE_TICKET_FROM_FORM_FIELD',
)

//ASSIGN employees

export const ASSIGN_NEW_USER_TO_TICKET = createActionSet(
  'ASSIGN_NEW_USER_TO_TICKET',
)

export const REMOVE_ASSIGNED_USER_FROM_TICKET = createActionSet(
  'REMOVE_ASSIGNED_USER_FROM_TICKET',
)

export const SCHEDULE_ASSIGNED_EMPLOYEE = createActionSet(
  'SCHEDULE_ASSIGNED_EMPLOYEE',
)

export const CREATE_TICKET_COMMENT = createActionSet('CREATE_TICKET_COMMENT')

export const TICKET_FEEDBACK = createActionSet('TICKET_FEEDBACK')

export const UNSCHEDULE_ASSIGNED_EMPLOYEE = createActionSet(
  'UNSCHEDULE_ASSIGNED_EMPLOYEE',
)

export const UPDATE_SCHEDULE_TIME_OF_EMPLOYEE = createActionSet(
  'UPDATE_SCHEDULE_TIME_OF_EMPLOYEE',
)

export const GET_TICKET_ASSIGNED_EMPLOYEE_LIST = createActionSet(
  'GET_TICKET_ASSIGNED_EMPLOYEE_LIST',
)
export const DOWNLOAD_TICKET_PLAN_PDF = createActionSet(
  'DOWNLOAD_TICKET_PLAN_PDF',
)

export const GET_TICKET_UNASSIGNED_EMPLOYEE_LIST = createActionSet(
  'GET_TICKET_UNASSIGNED_EMPLOYEE_LIST',
)

export const ASSIGN_TICKET_TO_SITE_VISIT = createActionSet(
  'ASSIGN_TICKET_TO_SITE_VISIT',
)

export const UNASSIGN_TICKET_TO_SITE_VISIT = createActionSet(
  'UNASSIGN_TICKET_TO_SITE_VISIT',
)

export const GET_TICKET_LOG_LIST = createActionSet('GET_TICKET_LOG_LIST')

export const GET_UN_ASSIGNED_TICKET_LIST = createActionSet(
  'GET_UN_ASSIGNED_TICKET_LIST',
)

export const GET_SCHEDULED_EMPLOYEE_OF_TICKET = createActionSet(
  'GET_SCHEDULED_EMPLOYEE_OF_TICKET',
)

export const GET_UNSCHEDULED_EMPLOYEE_OF_TICKET = createActionSet(
  'GET_UNSCHEDULED_EMPLOYEE_OF_TICKET',
)

export const ASSIGN_AND_SCHEDULE_USER_TO_TICKET = createActionSet(
  'ASSIGN_AND_SCHEDULE_USER_TO_TICKET',
)

export const SET_MARKER_COORDINATES = createActionSet('SET_MARKER_COORDINATES')

export const GET_LABOUR_ELEMENTS_OF_TICKET = createActionSet(
  'GET_LABOUR_ELEMENTS_OF_TICKET',
)

export const GET_LABOUR_ELEMENTS_OF_SUB_TICKET = createActionSet(
  'GET_LABOUR_ELEMENTS_OF_SUB_TICKET',
)

export const GET_LABOUR_ELEMENTS_OF_PARENT_TICKET = createActionSet(
  'GET_LABOUR_ELEMENTS_OF_PARENT_TICKET',
)

export const CREATE_SUB_TICKET = createActionSet('CREATE_SUB_TICKET')

export const MAKE_SUB_TICKET_LABOUR_COMPLETE = createActionSet(
  'MAKE_SUB_TICKET_LABOUR_COMPLETE',
)

export const UPDATE_SUB_TICKET = createActionSet('UPDATE_SUB_TICKET')

export const GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION =
  createActionSet('GET_TICKET_LABOUR_ELEMENTS_DURING_SUB_TICKET_CREATION')

export const REMOVE_SUB_TICKET = createActionSet('REMOVE_SUB_TICKET')

export const ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET = createActionSet(
  'ATTACH_LABOUR_ELEMENTS_TO_SUB_TICKET',
)

export const DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET = createActionSet(
  'DEATTACH_LABOUR_ELEMENTS_FROM_SUB_TICKET',
)

export const CREATE_DAYS_WORK_TICKET = createActionSet(
  'CREATE_DAYS_WORK_TICKET',
)

export const REMOVE_DAYS_WORK_TICKET = createActionSet(
  'REMOVE_DAYS_WORK_TICKET',
)

export const GET_PARENT_TICKET_DETAILS = createActionSet(
  'GET_PARENT_TICKET_DETAILS',
)

export const GET_TICKET_LIST_BY_PROJECT_FORM_ID = createActionSet(
  'GET_TICKET_LIST_BY_PROJECT_FORM_ID',
)

// *  Notices
export const GET_NOTICE = createActionSet('GET_NOTCE')

// * Calendar
export const GET_CALENDAR_EVENT_LIST = createActionSet(
  'GET_CALENDAR_EVENT_LIST',
)

export const CREATE_CALENDAR_EVENT = createActionSet('CREATE_CALENDAR_EVENT')
export const GET_ACTIVE_USER_OF_COMPANY = createActionSet(
  'GET_ACTIVE_USER_OF_COMPANY',
)
export const GET_ACTIVE_TICKET_OF_COMPANY = createActionSet(
  'GET_ACTIVE_TICKET_OF_COMPANY',
)

export const GET_UNASSIGNED_TICKET_FOR_CALENDAR = createActionSet(
  'GET_UNASSIGNED_TICKET_FOR_CALENDAR',
)

export const GET_ASSIGNED_TICKET_FOR_CALENDAR = createActionSet(
  'GET_ASSIGNED_TICKET_FOR_CALENDAR',
)

export const DELETE_INDIVIDUAL_PROJECT = createActionSet(
  'DELETE_INDIVIDUAL_PROJECT',
)

export const ARCHIVE_INDIVIDUAL_PROJECT = createActionSet(
  'ARCHIVE_INDIVIDUAL_PROJECT',
)

export const PATCH_CUSTOMER_LOGO = createActionSet('PATCH_CUSTOMER_LOGO')

export const DELETE_CUSTOMER_LOGO = createActionSet('DELETE_CUSTOMER_LOGO')

export const DELETE_COMPANY_LOGO = createActionSet('DELETE_COMPANY_LOGO')

export const CHANGE_INDIVIDUAL_USER_PASSWORD = createActionSet(
  'CHANGE_INDIVIDUAL_USER_PASSWORD',
)
