import {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {alterFormContent} from 'redux-src'

import {useIndividualFormElement} from 'hooks'
import {useAuth} from 'react-auth-navigation'

interface ProjectUserProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface ProjectUsersType {
  label: string
  description: string
  value: string
}

export const ProjectUserPreview = ({
  id,
  type,
  isEditable,
}: ProjectUserProps) => {
  const {projectUserList}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {userRole} = useAuth()
  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [multipleChoice, setMultipleChoice] = useState<{
    label: string
    description: string
    value: string
  }>(individualElement?.content as ProjectUsersType)

  const dispatch = useDispatch()

  // Sample options for the dropdown
  const projectOptions = [
    {
      value: '',
      label: `Select a ${
        individualElement?.content?.label?.length > 0
          ? individualElement?.content?.label
          : 'Project User'
      }`,
    },
    {value: 'project1', label: 'Project Alpha'},
    {value: 'project2', label: 'Project Beta'},
    {value: 'project3', label: 'Project Gamma'},
  ]

  const projectUsersList = useMemo(() => {
    if (!isEditable) {
      return projectOptions
    }

    const remappedList = projectUserList?.map((item) => ({
      label: item?.user_details?.display_name,
      value: item.user_details?.id,
    }))

    return remappedList?.length > 0
      ? [
          {
            value: '',
            label: `Select a ${
              individualElement?.content?.label?.length > 0
                ? individualElement?.content?.label
                : 'Project User'
            }`,
          },
          ...remappedList,
        ]
      : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUserList, isEditable])

  useEffect(() => {
    dispatch(alterFormContent(id, multipleChoice, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleChoice])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
        </div>
      )}
      <div className="py-4 px-12">
        <div className="relative w-full">
          <select
            value={multipleChoice?.value || ''}
            onChange={(e) => {
              setMultipleChoice((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }}
            disabled={
              !individualElement?.editableBy?.includes(userRole) || !isEditable
            }
            className="
              appearance-none 
              w-full 
              px-6 
              py-6 
              border 
              border-gray-300 
              rounded-md 
              text-gray-700 
              bg-white 
              shadow-sm 
              focus:outline-none 
              focus:ring-blue-500 
              focus:border-blue-500
              cursor-pointer
              outline-none
            "
          >
            {projectUsersList.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {/* Custom dropdown arrow */}
          <div
            className="
              pointer-events-none 
              absolute 
              inset-y-0 
              right-0 
              flex 
              items-center 
              px-2 
              text-gray-700
            "
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
    </div>
  ) : (
    <></>
  )
}

export const ProjectStatusPreview = ({
  id,
  type,
  isEditable,
}: ProjectUserProps) => {
  const {userRole} = useAuth()
  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [selectedOptions, setSelectedOptions] = useState<{
    label: string
    description: string
    value: string
  }>(individualElement?.content as any)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(alterFormContent(id, selectedOptions, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  // Sample options for the dropdown
  const projectStatusOptions = [
    {value: '', label: 'Select a project status'},
    {value: 'Open', label: 'Open'},
    {value: 'In_progress', label: 'In Progress'},
    {value: 'Resolved', label: 'Resolved'},
    {value: 'Feedback', label: 'Feedback'},
    {value: 'Closed', label: 'Closed'},
    {value: 'Rejected', label: 'Rejected'},
    {value: 'Completed', label: 'Completed'},
  ]

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
        </div>
      )}
      {/* Dropdown */}
      <div className="py-4 px-12">
        <div className="relative w-full">
          <select
            disabled={
              !individualElement?.editableBy?.includes(userRole) || !isEditable
            }
            value={selectedOptions?.value || ''}
            onChange={(e) => {
              setSelectedOptions((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }}
            className="
                appearance-none 
                w-full 
                px-6 
                py-6 
                border 
                border-gray-300 
                rounded-md 
                text-gray-700 
                bg-white 
                shadow-sm 
                focus:outline-none 
                focus:ring-blue-500 
                focus:border-blue-500
                cursor-pointer
                outline-none
              "
          >
            {projectStatusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {/* Custom dropdown arrow */}
          <div
            className="
                pointer-events-none 
                absolute 
                inset-y-0 
                right-0 
                flex 
                items-center 
                px-2 
                text-gray-700
              "
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>

      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
    </div>
  ) : (
    <></>
  )
}

export const ProgressBarPreview = ({
  id,
  type,
  isEditable,
}: ProjectUserProps) => {
  const {userRole} = useAuth()
  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [currentProgress, setCurrentProgress] = useState<any>({
    label: individualElement?.content?.label || '',
    description: individualElement?.content?.description || '',
    value: (individualElement?.content?.value as string) || '0',
  })

  const dispatch = useDispatch()
  const progressBarRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)

  const handleProgressChange = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation()
    if (
      !progressBarRef.current ||
      !isEditable ||
      !individualElement?.editableBy?.includes(userRole)
    )
      return

    const progressBar = progressBarRef.current
    const rect = progressBar.getBoundingClientRect()
    const clientX =
      'touches' in e
        ? (e as React.TouchEvent).touches[0].clientX
        : (e as React.MouseEvent).clientX

    const percentage = Math.max(
      0,
      Math.min(100, ((clientX - rect.left) / rect.width) * 100),
    )

    setCurrentProgress((prev: any) => ({
      ...prev,
      value: percentage.toFixed(0),
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, currentProgress, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgress])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
        </div>
      )}
      {/* Sliding Progress Bar */}
      <div
        ref={progressBarRef}
        className="w-full h-10 bg-gray-200 relative cursor-pointer rounded-sm"
        onMouseDown={handleProgressChange}
        onTouchStart={handleProgressChange}
        onMouseMove={(e) => e.buttons === 1 && handleProgressChange(e)}
        onTouchMove={handleProgressChange}
      >
        <div
          ref={thumbRef}
          className="absolute top-0 bottom-0 w-6 bg-blue-500 transition-all duration-200"
          style={{
            left: `calc(${currentProgress.value}% - 5px)`,
          }}
        />
        <span className="text-transparent pb-6">Progress Bar</span>
        <div
          className="absolute top-0 bottom-0 bg-blue-300 opacity-50"
          style={{
            width: `${currentProgress.value}%`,
          }}
        />
      </div>

      <div className="flex flex-col p-2 py-6">
        <div className="text-sm text-gray-600">
          Progress: {currentProgress.value}%
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
