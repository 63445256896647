import {useIndividualFormElement} from 'hooks'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import ReactDatePicker from 'react-datepicker'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

interface TimeInputPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface TimeInputContentType {
  label: string
  description: string
  value: string
}

export const TimeInputPreview = ({
  id,
  type,
  isEditable,
}: TimeInputPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [timeInput, setTimeInput] = useState<TimeInputContentType>(
    individualElement?.content as TimeInputContentType,
  )

  const dispatch = useDispatch()

  const handleChangeInput = (time: any) => {
    const selectedTime = new Date(time)

    setTimeInput((prev: any) => ({
      ...prev,
      value: selectedTime,
    }))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, timeInput, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInput])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div className="h-[35px] flex w-full items-center border border-solid border-1 border-gray-300 rounded-sm">
        <ReactDatePicker
          selected={timeInput?.value ? new Date(timeInput?.value) : undefined}
          onChange={(date: any) => {
            handleChangeInput(moment(date).toISOString())
          }}
          className={`text-black w-full h-full text-sm px-6 bg-transparent`}
          placeholderText="hh:mm"
          disabled={
            // type !== 'web view' ||
            !individualElement?.editableBy?.includes(userRole) || !isEditable
          }
          showTimeSelect
          showTimeSelectOnly
          dateFormat="h:mm aa"
          timeCaption="Time"
          timeIntervals={30}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
