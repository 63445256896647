import {Checkbox, ToolTip} from 'app/common'
import {TicketContext} from 'app/pages/jobs/pages/ticket'
import {getAssetUrl} from 'helpers'
import {useIndividualFormElement} from 'hooks'
import {useContext, useEffect, useState} from 'react'
import {useAuth, useNavigation} from 'react-auth-navigation'
import {IoCloudUploadOutline, IoTrashOutline} from 'react-icons/io5'
import {useDispatch} from 'react-redux'
import {
  addTicketFormFieldAttachments,
  alterFormContent,
  getTicketDetailsById,
  removeTicketFormFieldAttachments,
} from 'redux-src'

interface PhotoPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface SignaturePreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface VideoPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface ImagePreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
}

interface ImageContentType {
  label: string
  description: string
  value: string
}

export const PhotoPreview = ({id, type, isEditable}: PhotoPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )
  const {projectTicketId} = useContext(TicketContext)
  const {params} = useNavigation()
  const {projectId} = params as any

  const [isMulti, setIsMulti] = useState<boolean>(false)

  const [photoContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType,
  )

  const dispatch = useDispatch()

  const changeImage = (data: any) => {
    if (data) {
      const assetsFormData = new FormData()

      assetsFormData.append('file', data)

      dispatch(
        addTicketFormFieldAttachments(
          projectId,
          projectTicketId,
          individualElement?.id,
          assetsFormData,
          () => {
            dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          },
        ),
      )
    }
  }

  const deleteAttachment = (assetId: number) => {
    const deletedIds = {
      ticketAssetIds: [assetId],
    }

    if (assetId) {
      dispatch(
        removeTicketFormFieldAttachments(
          projectId,
          projectTicketId,
          individualElement?.id,
          deletedIds,
          () => {
            dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          },
        ),
      )
    }
  }

  useEffect(() => {
    dispatch(alterFormContent(id, photoContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoContent])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="text-md text-black flex items-center justify-between">
        <div>
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>

        {type === 'web view' && (
          <div className="">
            <Checkbox
              label={'Multiple Photos'}
              dataId={`multiple-photos-${id}`}
              isChecked={isMulti}
              onChange={() => {
                setIsMulti((prev) => !prev)
              }}
            />
          </div>
        )}
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}

      {!!!individualElement?.assets ? (
        <div className="h-[100px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center">
          {isEditable && (
            <label
              htmlFor={`photo-${id}`}
              className="flex flex-1 h-full flex-col justify-center gap-4 items-center px-10 bg-purple-100 cursor-pointer"
            >
              <IoCloudUploadOutline size={20} className="text-gray-400" />
              <div className="text-gray-400">Upload</div>
            </label>
          )}
          <div className="h-full w-full flex items-center justify-center text-gray-400 px-20 cursor-pointer">
            End user completing the form will put their photo here{' '}
          </div>
        </div>
      ) : (
        <div className="min-h-[150px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center gap-8">
          {individualElement?.assets?.slice(0, 4)?.map((image) => {
            return (
              <div
                className="h-[140px] w-[160px] rounded-md bg-blue-100 relative"
                key={image?.asset_details?.id}
              >
                <img
                  src={getAssetUrl(image?.asset_details)}
                  alt={image?.asset_details?.label}
                  className="h-full w-full object-contain"
                />
                <div
                  className="absolute top-2 right-4 cursor-pointer"
                  onClick={() => {
                    deleteAttachment(image?.ticket_asset_id)
                  }}
                >
                  <IoTrashOutline size={18} color="crimson" />
                </div>
              </div>
            )
          })}

          {individualElement?.settings?.multipleUpload && (
            <label
              htmlFor={`photo-${id}`}
              className="flex flex-1 w-[100px] h-full flex-col justify-center gap-4 items-center px-10 bg-purple-100 cursor-pointer"
            >
              <IoCloudUploadOutline size={20} className="text-gray-400" />
              <div className="text-gray-400">Upload</div>
            </label>
          )}
        </div>
      )}

      <input
        type="file"
        name="value"
        id={`photo-${id}`}
        className="hidden"
        accept="image/*"
        onChange={(e: any) => {
          // setPhotoContent((prev) => ({...prev, value: e.target.files}))
          changeImage(e.target.files[0])
        }}
      />
    </div>
  ) : (
    <></>
  )
}

export const SignaturePreview = ({
  id,
  type,
  isEditable,
}: SignaturePreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [signatureContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType,
  )

  const {projectTicketId} = useContext(TicketContext)
  const {params} = useNavigation()
  const {projectId} = params as any

  const dispatch = useDispatch()

  const changeSignature = (data: any) => {
    if (data) {
      const assetsFormData = new FormData()

      assetsFormData.append('file', data)

      dispatch(
        addTicketFormFieldAttachments(
          projectId,
          projectTicketId,
          individualElement?.id,
          assetsFormData,
          () => {
            dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          },
        ),
      )
    }
  }

  const deleteAttachment = (assetId: number) => {
    const deletedIds = {
      ticketAssetIds: [assetId],
    }

    if (assetId) {
      dispatch(
        removeTicketFormFieldAttachments(
          projectId,
          projectTicketId,
          individualElement?.id,
          deletedIds,
          () => {
            dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          },
        ),
      )
    }
  }

  useEffect(() => {
    dispatch(alterFormContent(id, signatureContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureContent])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="flex justify-between items-center">
        <div className="text-md text-black">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}
      {!!!individualElement?.assets ? (
        <div className="h-[100px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center">
          {isEditable && (
            <label
              htmlFor={`signature-${id}`}
              className="flex flex-1 w-[100px] h-full flex-col justify-center gap-4 items-center px-10 bg-purple-100 cursor-pointer"
            >
              <IoCloudUploadOutline size={20} className="text-gray-400" />
              <div className="text-gray-400">Upload</div>
            </label>
          )}
          <span className="h-full w-full flex items-center justify-center text-gray-400 px-20">
            End user completing the form will put their signature here{' '}
          </span>
        </div>
      ) : (
        <div className="min-h-[150px]  w-full border border-1 border-solid border-gray-200 flex gap-8 items-center justify-center">
          {individualElement?.assets?.slice(-1)?.map((signature) => {
            // console.log({iE: individualElement?.assets?.slice(-1)})
            return (
              <div
                className="h-[140px] w-[160px] flex-1 bg-blue-100 relative"
                key={signature?.asset_details?.id}
              >
                <img
                  src={getAssetUrl(signature?.asset_details)}
                  alt={signature?.asset_details?.label}
                  className="h-full w-full object-contain"
                />

                <div
                  className="absolute top-2 right-4 cursor-pointer"
                  onClick={() => {
                    deleteAttachment(signature?.ticket_asset_id)
                  }}
                >
                  <ToolTip text="Remove">
                    <IoTrashOutline size={18} color="crimson" />
                  </ToolTip>
                </div>
              </div>
            )
          })}

          {/* <label
            htmlFor={`signature-${id}`}
            className="flex flex-2 w-[100px] h-full flex-col justify-center gap-4 items-center px-10 bg-purple-100 cursor-pointer"
          >
            <MdFindReplace size={20} className="text-gray-400" />
            <div className="text-gray-400">Replace</div>
          </label> */}
        </div>
      )}

      <input
        type="file"
        name="value"
        id={`signature-${id}`}
        className="hidden"
        onChange={(e: any) => {
          // setSignatureContent((prev) => ({...prev, value: e.target.files}))
          changeSignature(e.target.files[0])
        }}
        accept="image/*"
      />
    </div>
  ) : (
    <></>
  )
}

export const VideoPreview = ({id, type, isEditable}: VideoPreviewProps) => {
  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [videoContent] = useState<ImageContentType>(
    individualElement?.content as ImageContentType,
  )
  const [isMulti, setIsMulti] = useState<boolean>(false)

  const {userRole} = useAuth()
  const {projectTicketId} = useContext(TicketContext)
  const {params} = useNavigation()
  const {projectId} = params as any
  const dispatch = useDispatch()

  const changeVideo = (data: any) => {
    if (data) {
      const assetsFormData = new FormData()

      assetsFormData.append('file', data)

      dispatch(
        addTicketFormFieldAttachments(
          projectId,
          projectTicketId,
          individualElement?.id,
          assetsFormData,
          () => {
            dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          },
        ),
      )
    }
  }

  const deleteAttachment = (assetId: number) => {
    const deletedIds = {
      ticketAssetIds: [assetId],
    }

    if (assetId) {
      dispatch(
        removeTicketFormFieldAttachments(
          projectId,
          projectTicketId,
          individualElement?.id,
          deletedIds,
          () => {
            dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          },
        ),
      )
    }
  }

  useEffect(() => {
    videoContent && dispatch(alterFormContent(id, videoContent, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoContent])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="text-md text-black flex items-center justify-between">
        <div>
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
        {type === 'web view' && (
          <div className="">
            <Checkbox
              label={'Multiple Video'}
              dataId={`multiple-video-${id}`}
              isChecked={isMulti}
              onChange={() => {
                setIsMulti((prev) => !prev)
              }}
            />
          </div>
        )}
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}
      {!!!individualElement?.assets ? (
        <div className="h-[100px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center">
          {isEditable && (
            <label
              htmlFor={`video-${id}`}
              className="flex flex-1 w-[100px] h-full flex-col justify-center gap-4 items-center px-10 bg-purple-100 cursor-pointer"
            >
              <IoCloudUploadOutline size={20} className="text-gray-400" />
              <div className="text-gray-400">Upload</div>
            </label>
          )}
          <span className="h-full w-full flex items-center justify-center text-gray-400 px-20">
            End user completing the form will upload their video here{' '}
          </span>
        </div>
      ) : (
        <div className="min-h-[150px] w-full border border-1 border-solid border-gray-200 flex items-center justify-center gap-8">
          {individualElement?.assets?.slice(0, 3)?.map((video) => {
            return (
              <div
                className="h-[140px] w-[160px] bg-blue-100 relative"
                key={video?.asset_details?.id}
              >
                <video
                  src={getAssetUrl(video?.asset_details)}
                  controls
                  className="object-contain w-full h-full"
                />

                <div
                  className="absolute top-2 right-4 cursor-pointer"
                  onClick={() => {
                    deleteAttachment(video?.ticket_asset_id)
                  }}
                >
                  <ToolTip text="Remove">
                    <IoTrashOutline size={18} color="crimson" />
                  </ToolTip>
                </div>
              </div>
            )
          })}

          {individualElement?.settings?.multipleUpload && (
            <label
              htmlFor={`video-${id}`}
              className="flex flex-1 w-[100px] h-full flex-col justify-center gap-4 items-center px-10 bg-purple-100 cursor-pointer"
            >
              <IoCloudUploadOutline size={20} className="text-gray-400" />
              <div className="text-gray-400">Upload</div>
            </label>
          )}
        </div>
      )}

      <input
        type="file"
        name="value"
        id={`video-${id}`}
        className="hidden"
        accept="video/*"
        onChange={(e: any) => {
          // setVideoContent((prev) => ({...prev, value: e.target.files}))
          changeVideo(e.target.files[0])
        }}
      />
    </div>
  ) : (
    <></>
  )
}

export const ImagePreview = ({id, type}: ImagePreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col gap-4 ${individualStyles}`}>
      <div className="text-md text-black">
        {individualElement?.content?.label}
      </div>
      {individualElement?.content?.description && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div
        key={id}
        className="flex-col w-full my-6 bg-blue-100 flex h-[150px] relative"
      >
        {individualElement?.asset_details ? (
          <img
            src={
              individualElement?.asset_details
                ? String(
                    getAssetUrl(individualElement?.asset_details) ??
                      '/noImgTemplate.png',
                  )
                : '/noImgTemplate.png'
            }
            alt="previewImage"
            className="h-[150px] w-full object-contain"
          />
        ) : (
          <img
            src={
              individualElement?.content?.value &&
              individualElement?.content?.value?.length > 0
                ? String(
                    individualElement?.content?.value ?? '/noImgTemplate.png',
                  )
                : '/noImgTemplate.png'
            }
            alt="previewImage"
            className="h-[150px] w-full object-contain"
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}
