import {APIS, api} from 'config'
import {
  ACTIVATE_FORM,
  ADD_FORM_ASSETS,
  ADD_TICKET_FORM_FIELDS_ATTACHMENTS,
  BULK_UPDATE_FORM_FIELDS_ORDER,
  CREATE_FORM,
  CREATE_PROJECT_FORM,
  DEACTIVATE_FORM,
  DELETE_PROJECT_FORM,
  GET_FORM_BY_ID,
  GET_FORM_FIELD_DATA_TYPE,
  GET_FORM_LIST,
  GET_PROJECT_DETAILS_BY_PROJECTFORMID,
  GET_PROJECT_FORM_BY_ID,
  GET_PROJECT_LIST_BY_FORM_ID,
  GET_UNATTACHED_FORM_BY_PROJECT_ID,
  PUBLISH_FORM,
  REMOVE_FORM_ASSETS,
  REMOVE_TICKET_FORM_FIELDS_ATTACHMENTS,
  UPDATE_FORM_FIELD,
  UPDATE_PROJECT_MANDATORIES,
} from './actionTypes.action'
import {capitalizeFirstLetterWithSpace, errorHandler} from 'utils'
import {toast} from 'app/common'
import {
  defaultDataEntryElements,
  defaultInstructionsElements,
  // formElementsConstants
} from 'app/pages/settings/pages/forms/constants'
import {updateFormFromApi} from './formBuilder.action'
import {getParsedUrl} from 'helpers'

export function createFormAction(
  formBody: {
    formName: string
    fields: Array<{
      dataTypeId: number
      // order: number
      editableBy: string[]
      visibleTo: string[]
      properties: RT.FormElementType
    }>
  },
  successCallback?: (formId: number) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_FORM.LOADING})
      res = await api<Api.Base<{form: {id: number}}>>(
        `${APIS.forms}`,
        'POST',
        formBody,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_FORM.SUCCESS, payload: data})

        successCallback && successCallback(data.form.id)
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_FORM.ERROR, payload: message})
        return 0
      }
    } catch (err) {
      dispatch({type: CREATE_FORM.ERROR})
      console.log({err})
      toast.error('Error creating new form!')
      return 1
    }
  }
}

export function getFormByIdAction(
  formId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_FORM_BY_ID.LOADING})

      res = await api<Api.Base<Api.IndividualFormById>>(
        `${APIS.forms}/${formId}`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: GET_FORM_BY_ID.SUCCESS, payload: data})
        dispatch(
          updateFormFromApi({
            ...data,
            fields: data?.fields?.map((formField) => ({
              ...formField,
              properties: {
                ...formField?.properties,
              },
              content: formField?.properties?.content,
              asset_details: formField.properties.asset_details,
              data_type: 'name',
              assets: formField?.assets,
            })),
          }),
        )
        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_FORM_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_FORM_BY_ID.ERROR})
      return 0
    }
  }
}

export function getFormLists(
  params: {
    page?: number
    limit?: number
    search?: string
    status?: string
  } = {
    page: 1,
    limit: 10,
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_FORM_LIST.LOADING})

      res = await api<Api.Base<Api.FormList>>(
        getParsedUrl(`${APIS.forms}`, params),
      )

      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({type: GET_FORM_LIST.SUCCESS, payload: {total, rows}})
        // dispatch(updateFormFromApi(data))
        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_FORM_LIST.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_FORM_LIST.ERROR})
      return 0
    }
  }
}

export function getFormFieldDataType(successCallback?: () => void) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_FORM_FIELD_DATA_TYPE.LOADING})

      res = await api<Api.Base<Api.FormFieldDataType[]>>(
        `${APIS.forms}/field-data-types`,
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        const newElements = data?.reduce(
          (acc, {data_type, behaviour: formEl, id}) => {
            // (acc, {data_type: formType, id}) => {
            //   const {data_type, behaviour: formEl} = formElementsConstants.find(
            //     (el) => el.data_type === formType
            //   )

            const newAcc = {
              ...acc,
              formFieldDataTypeData: {
                ...acc.formFieldDataTypeData,
                [data_type as RT.FormBuilderType]: {...formEl, dataTypeId: id},
              },
              formElementSettings: {
                ...acc.formElementSettings,
                [data_type as RT.FormBuilderType]: formEl.settings
                  ? Object.entries(formEl.settings)?.map(
                      ([key, value], index) => ({
                        id: index + 1,
                        label: key,
                        isChecked: value,
                      }),
                    )
                  : [],
              },
            }

            if (formEl.elementType === 'instructional') {
              const newEl = {
                id: formEl.id,
                formType: formEl.formType,
                heading: capitalizeFirstLetterWithSpace(formEl.formType),
                icon: defaultInstructionsElements?.find(
                  (defFormEl) => formEl.formType === defFormEl.formType,
                )?.icon,
              }
              return {
                ...newAcc,
                instructionsElements: [...acc.instructionsElements, newEl],
              }
            } else if (formEl.elementType === 'data-entry') {
              const newEl = {
                id: formEl.id,
                formType: formEl.formType,
                heading: capitalizeFirstLetterWithSpace(formEl.formType),
                icon: defaultDataEntryElements?.find(
                  (defFormEl) => formEl.formType === defFormEl.formType,
                )?.icon,
              }
              return {
                ...newAcc,
                dataEntryElements: [...acc.dataEntryElements, newEl],
              }
            }

            return newAcc
          },
          {
            instructionsElements: [],
            dataEntryElements: [],
            formFieldDataTypeData: {},
            formElementSettings: {},
          } as {
            instructionsElements: Array<RT.DefaultElementType>
            dataEntryElements: Array<RT.DefaultElementType>
            formFieldDataTypeData: RT.FormElementListType
            formElementSettings: RT.FormElementSettingsType
          },
        )

        console.log({newElements: JSON.stringify(newElements)})

        dispatch({type: GET_FORM_FIELD_DATA_TYPE.SUCCESS, payload: newElements})
        successCallback?.()
        return 1
      } else {
        dispatch({type: GET_FORM_FIELD_DATA_TYPE.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_FORM_FIELD_DATA_TYPE.ERROR})

      console.log({formFieldDataTypeActionError: e})
      return 0
    }
  }
}

export function updateFormFieldAction(
  formId: number,
  formBody: {
    addedFields: Array<{
      dataTypeId: number
      // order: number
      editableBy: Array<string>
      visibleTo: Array<string>
      properties: RT.FormElementType
    }>
    updatedFields: Array<{
      id: number
      dataTypeId: number
      // order: number
      editableBy: Array<string>
      visibleTo: Array<string>
      properties: RT.FormElementType
    }>
    deletedFields: Array<{id: number}>
  },
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_FORM_FIELD.LOADING})
      res = await api<any>(`${APIS.forms}/${formId}`, 'PATCH', formBody)
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_FORM_FIELD.SUCCESS, payload: data})
        toast.success(message)
        successCallback?.()
        return 1
      } else {
        dispatch({type: UPDATE_FORM_FIELD.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (err) {
      dispatch({type: UPDATE_FORM_FIELD.ERROR})
      // errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

//MARK: PUBLISH FORM TEMPLATE

export function publishFormTemplateAction(
  formId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: PUBLISH_FORM.LOADING})
      res = await api<Api.Base<any>>(`${APIS.forms}/publish/${formId}`, 'PATCH')
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: PUBLISH_FORM.SUCCESS, payload: data})

        toast.success(message ?? 'Successfully published form template !!')
        successCallback?.()
        return 1
      } else {
        dispatch({type: PUBLISH_FORM.ERROR, payload: message})
        return 0
      }
    } catch (err) {
      dispatch({type: PUBLISH_FORM.ERROR})
      console.log({err})
      toast.error('Error publishing new form!')
      return 1
    }
  }
}

export function bulkUpdateFormFieldsOrder(
  formId: string | number,
  formBody: {
    items: {
      id: number | string
      order: number | string
    }[]
  },
  toast: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: BULK_UPDATE_FORM_FIELDS_ORDER.LOADING})
      res = await api<any>(
        `${APIS.forms}/${formId}/update-form-fields-order`,
        'PATCH',
        formBody,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: BULK_UPDATE_FORM_FIELDS_ORDER.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: BULK_UPDATE_FORM_FIELDS_ORDER, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (err) {
      dispatch({type: BULK_UPDATE_FORM_FIELDS_ORDER.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deactivateFormTemplate(
  formId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DEACTIVATE_FORM.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.forms}/deactivate/${formId}`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: DEACTIVATE_FORM.SUCCESS, payload: data})
        toast.success(message ?? 'Successfully deactivated form !!')
        successCallback?.()
        return 1
      } else {
        dispatch({type: DEACTIVATE_FORM.ERROR, payload: message})
        toast.error(message ?? '')
        return 0
      }
    } catch (e) {
      dispatch({type: DEACTIVATE_FORM.ERROR})
      return 0
    }
  }
}

export function activateFormTemplate(
  formId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ACTIVATE_FORM.LOADING})

      res = await api<Api.Base<any>>(
        `${APIS.forms}/activate/${formId}`,
        'PATCH',
      )

      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: ACTIVATE_FORM.SUCCESS, payload: data})
        toast.success(message ?? 'Successfully activated form !!')
        successCallback?.()
        return 1
      } else {
        dispatch({type: ACTIVATE_FORM.ERROR, payload: message})
        toast.error(message ?? '')
        return 0
      }
    } catch (e) {
      dispatch({type: ACTIVATE_FORM.ERROR})
      return 0
    }
  }
}

// for project forms

export function createProjectFormAction(
  projectId: number,
  body: {
    formId: string | number
  },
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: CREATE_PROJECT_FORM.LOADING})
      res = await api<any>(
        `${APIS.forms}/project-forms/${projectId}`,
        'POST',
        body,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: CREATE_PROJECT_FORM.SUCCESS, payload: data})

        successCallback && successCallback(data)
        toast.success(message)
        return 1
      } else {
        dispatch({type: CREATE_PROJECT_FORM.ERROR, payload: message})
        errorHandler(res.data, toast)

        return 0
      }
    } catch (err) {
      dispatch({type: CREATE_PROJECT_FORM.ERROR})
      toast.error('Error Creating new project form !')
      return 1
    }
  }
}

export function getProjectFormByIdAction(
  projectId: number,
  params?: {
    page: number
    limit: number
    search: string
  },
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_FORM_BY_ID.LOADING})
      res = await api<Api.Base<Api.ProjectFormByIdTypeList>>(
        getParsedUrl(`${APIS.forms}/project-forms/${projectId}`, params),
      )
      const {
        success,
        data: {
          data: {rows, total},
          message,
        },
      } = res.data

      if (success) {
        dispatch({type: GET_PROJECT_FORM_BY_ID.SUCCESS, payload: {rows, total}})

        return 1
      } else {
        dispatch({type: GET_PROJECT_FORM_BY_ID.ERROR, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_FORM_BY_ID.ERROR})
      return 0
    }
  }
}

export function getProjectFormDetailsByProjectFormId(
  projectId: number,
  projectFormId: number,
  successCallback?: (form: Api.IndividualFormById) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_DETAILS_BY_PROJECTFORMID.LOADING})
      res = await api<Api.Base<Api.ProjectDetailsByFormId>>(
        `${APIS.forms}/project-forms/${projectId}/${projectFormId}`,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_DETAILS_BY_PROJECTFORMID.SUCCESS,
          payload: data,
        })

        successCallback?.({
          form_name: data?.project_form_details?.form_name,
          fields: data?.form_fields,
        })

        return 1
      } else {
        dispatch({type: GET_PROJECT_DETAILS_BY_PROJECTFORMID, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_DETAILS_BY_PROJECTFORMID.ERROR})
      return 0
    }
  }
}

export function updateProjectMandatories(
  projectformId: string | number,
  mandatories: number[],
  toast: any,
  successCallback?: any,
  onErrorCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: UPDATE_PROJECT_MANDATORIES.LOADING})
      res = await api<any>(
        `${APIS.forms}/project-forms/mandatories/${projectformId}`,
        'PATCH',
        mandatories,
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: UPDATE_PROJECT_MANDATORIES.SUCCESS, payload: data})
        successCallback && successCallback()
        toast.success(message)
        return 1
      } else {
        dispatch({type: UPDATE_PROJECT_MANDATORIES.ERROR, payload: message})
        errorHandler(res.data, toast)

        onErrorCallback && onErrorCallback()
        return 0
      }
    } catch (err) {
      dispatch({type: UPDATE_PROJECT_MANDATORIES.ERROR})
      errorHandler(res.data, toast)

      onErrorCallback && onErrorCallback()
      return 1
    }
  }
}

export function deleteProjectFormAction(
  projectId: number,
  projectFormId: number,
  successCallback?: () => void,
  onErrorCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: DELETE_PROJECT_FORM.LOADING})
      res = await api<any>(
        `${APIS.forms}/project-forms/${projectId}/${projectFormId}`,
        'DELETE',
      )
      const {
        success,
        data: {data, message},
      } = res.data

      if (success) {
        dispatch({type: DELETE_PROJECT_FORM.SUCCESS, payload: data})
        successCallback?.()
        toast.success(message)
        return 1
      } else {
        dispatch({type: DELETE_PROJECT_FORM.ERROR, payload: message})
        onErrorCallback?.()
        return 0
      }
    } catch (err) {
      dispatch({type: DELETE_PROJECT_FORM.ERROR})
      onErrorCallback?.()
      return 1
    }
  }
}

export function addFormAssets(
  body: FormData,
  successCallback?: (assetDetails: {
    asset_details: Api.AssetDetailsType
  }) => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_FORM_ASSETS.LOADING})

      res = await api<
        Api.Base<
          {
            asset_details: Api.AssetDetailsType
          }[]
        >
      >(`/forms/assets`, 'POST', body)

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({type: ADD_FORM_ASSETS.SUCCESS})

        successCallback && successCallback(data?.at(0))
        toast.success('Image successfully added!')
        return 1
      } else {
        dispatch({type: ADD_FORM_ASSETS.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: ADD_FORM_ASSETS.ERROR, payload: message})
      toast.error('Error while adding image!')

      return 0
    }
  }
}

export function removeFormAssets(
  body: {assetIds: Array<number>},
  successCallback?: any,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_FORM_ASSETS.LOADING})

      res = await api<Api.Base<null>>(`/forms/assets`, 'DELETE', body)

      const {
        success,
        data: {message},
      } = res.data

      if (success) {
        dispatch({type: REMOVE_FORM_ASSETS.SUCCESS})

        successCallback?.()

        return 1
      } else {
        dispatch({type: REMOVE_FORM_ASSETS.ERROR, payload: message})
        return 0
      }
    } catch ({message}: any) {
      dispatch({type: REMOVE_FORM_ASSETS.ERROR, payload: message})
      toast.error('Error while removing image!')

      return 0
    }
  }
}

//MARK: Project list by form Id

export function getProjectListByFormId(
  params: {
    page: number
    limit: number
  },
  formId: number,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_PROJECT_LIST_BY_FORM_ID.LOADING})
      res = await api<Api.Base<Api.ProjectListByFormIdList>>(
        getParsedUrl(`${APIS.projects}/form/${formId}`, params),
      )
      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_PROJECT_LIST_BY_FORM_ID.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()

        return 1
      } else {
        dispatch({type: GET_PROJECT_LIST_BY_FORM_ID, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_PROJECT_LIST_BY_FORM_ID.ERROR})
      return 0
    }
  }
}

//MARK: Get Un attached forms

export function getUnattachedFormsAction(
  projectId: number,
  params: {
    page: number
    limit: number
    search: string
    status: string
  },
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: GET_UNATTACHED_FORM_BY_PROJECT_ID.LOADING})
      res = await api<Api.Base<any>>(
        getParsedUrl(
          `${APIS.forms}/project-forms/unattached/${projectId}`,
          params,
        ),
      )
      const {
        success,
        data: {
          data: {total, rows},
          message,
        },
      } = res.data

      if (success) {
        dispatch({
          type: GET_UNATTACHED_FORM_BY_PROJECT_ID.SUCCESS,
          payload: {total, rows},
        })

        successCallback?.()

        return 1
      } else {
        dispatch({type: GET_UNATTACHED_FORM_BY_PROJECT_ID, payload: message})
        return 0
      }
    } catch (e) {
      dispatch({type: GET_UNATTACHED_FORM_BY_PROJECT_ID.ERROR})
      return 0
    }
  }
}

// Add Ticket Form Field Attachments
export function addTicketFormFieldAttachments(
  projectId: number,
  ticketId: number,
  formFieldId: number,
  body: FormData,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: ADD_TICKET_FORM_FIELDS_ATTACHMENTS.LOADING})

      res = await api<
        Api.Base<
          {
            asset_details: Api.AssetDetailsType
          }[]
        >
      >(
        `/projects/${projectId}/tickets/${ticketId}/form-fields/${formFieldId}/attachments`,
        'POST',
        body,
      )

      console.log({res: res.data})

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({type: ADD_TICKET_FORM_FIELDS_ATTACHMENTS.SUCCESS})

        successCallback?.()
        toast.success(message ?? 'Image successfully added!')
        return 1
      } else {
        dispatch({
          type: ADD_TICKET_FORM_FIELDS_ATTACHMENTS.ERROR,
          payload: message,
        })
        return 0
      }
    } catch ({message}: any) {
      dispatch({
        type: ADD_TICKET_FORM_FIELDS_ATTACHMENTS.ERROR,
        payload: message,
      })
      toast.error('Error while adding image!')

      return 0
    }
  }
}

// Remove Ticket Form Field Attachments
export function removeTicketFormFieldAttachments(
  projectId: number,
  ticketId: number,
  formFieldId: number,
  body: any,
  successCallback?: () => void,
) {
  return async function (dispatch: any) {
    let res
    try {
      dispatch({type: REMOVE_TICKET_FORM_FIELDS_ATTACHMENTS.LOADING})

      res = await api<
        Api.Base<
          {
            asset_details: Api.AssetDetailsType
          }[]
        >
      >(
        `/projects/${projectId}/tickets/${ticketId}/form-fields/${formFieldId}/attachments`,
        'DELETE',
        body,
      )

      const {
        success,
        data: {message, data},
      } = res.data

      if (success) {
        dispatch({type: REMOVE_TICKET_FORM_FIELDS_ATTACHMENTS.SUCCESS})

        successCallback && successCallback()
        toast.success('Image successfully removed!')
        return 1
      } else {
        dispatch({
          type: REMOVE_TICKET_FORM_FIELDS_ATTACHMENTS.ERROR,
          payload: message,
        })
        return 0
      }
    } catch ({message}: any) {
      dispatch({
        type: REMOVE_TICKET_FORM_FIELDS_ATTACHMENTS.ERROR,
        payload: message,
      })
      toast.error('Error while removing image!')

      return 0
    }
  }
}
