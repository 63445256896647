import {useIndividualFormElement} from 'hooks'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import ReactDatePicker from 'react-datepicker'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

interface DateInputPreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface DateInputContentType {
  label: string
  description: string
  value: string
}

export const DateInputPreview = ({
  id,
  type,
  isEditable,
}: DateInputPreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [dateDetails, setDateDetails] = useState<DateInputContentType>()

  const dispatch = useDispatch()

  const handleChangeInput = (date: any) => {
    setDateDetails((prev: any) => ({
      ...prev,
      value: new Date(date),
    }))
  }

  useEffect(() => {
    !!individualElement?.content?.value &&
      setDateDetails(individualElement?.content as DateInputContentType)
  }, [individualElement])

  useEffect(() => {
    dispatch(alterFormContent(id, dateDetails, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateDetails])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md text-black ">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div className="w-full flex items-center border border-solid border-1 border-gray-300 rounded-sm">
        <ReactDatePicker
          selected={
            dateDetails?.value ? new Date(dateDetails?.value) : undefined
          }
          onChange={(date: Date) =>
            handleChangeInput(moment(date).toISOString())
          }
          className={`text-black w-full h-[35px] text-sm px-6 bg-transparent`}
          placeholderText="Select Date"
          disabled={
            // type !== 'web view' ||
            !individualElement?.editableBy?.includes(userRole) || !isEditable
          }
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
