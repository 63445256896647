import {useIndividualFormElement} from 'hooks'
import {useEffect, useState} from 'react'
import {useAuth} from 'react-auth-navigation'
import {useDispatch} from 'react-redux'
import {alterFormContent} from 'redux-src'

interface SingleChoicePreviewProps {
  id: number
  type: 'builder' | 'preview' | 'web view'
  isEditable?: boolean
}

interface SingleChoiceContentType {
  label: string
  description: string
  value: Array<{id: number; text: string; isChecked: boolean}>
}

export const SingleChoicePreview = ({
  id,
  type,
  isEditable,
}: SingleChoicePreviewProps) => {
  const {userRole} = useAuth()

  const {individualElement, individualStyles} = useIndividualFormElement(
    id,
    type,
  )

  const [singleChoiceDetails, setSingleChoice] = useState<{
    label: string
    description: string
    value: Array<{id: number; text: string; isChecked: boolean}>
  }>(individualElement?.content as SingleChoiceContentType)

  const dispatch = useDispatch()

  const onChangeOptions = (event: any, choiceId: number) => {
    const tempSingleChoiceList = [...singleChoiceDetails?.value]

    const updatedChoiceList = tempSingleChoiceList?.map((choiceItem) => {
      if (choiceItem?.id === choiceId) {
        return {
          ...choiceItem,
          isChecked: !choiceItem?.isChecked,
        }
      } else {
        return {...choiceItem, isChecked: false}
      }
    })

    setSingleChoice((prev) => ({...prev, value: updatedChoiceList}))
  }

  useEffect(() => {
    dispatch(alterFormContent(id, singleChoiceDetails, () => {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleChoiceDetails])

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className={`flex flex-col ${individualStyles}`}>
      {!individualElement?.settings?.hideLabel && (
        <div className="text-md font-medium text-black">
          {individualElement?.content?.label}
          {individualElement?.settings?.markAsRequired && (
            <span className="text-red-300 font-bold pl-8">*</span>
          )}
        </div>
      )}
      {!individualElement?.settings?.hideDescription && (
        <div>{individualElement?.content?.description}</div>
      )}
      <div className="">
        {Array.isArray(individualElement?.content?.value) &&
          individualElement?.content?.value?.map((singleChoice) => {
            return (
              <label
                key={singleChoice?.id}
                className="flex items-center gap-6 bg-none px-6 rounded-sm w-[max-content]"
                htmlFor={`singleChoice-${singleChoice?.id}`}
              >
                <input
                  type="radio"
                  name="radio"
                  checked={singleChoice?.isChecked}
                  id={`singleChoice-${singleChoice?.id}`}
                  disabled={
                    // type !== 'web view' &&
                    !individualElement?.editableBy?.includes(userRole) ||
                    !isEditable
                  }
                  onChange={(e) => {
                    onChangeOptions(e, singleChoice?.id)
                  }}
                />
                <span className="px-4 py-6 w-[50%] mt-4 rounded-xs text-sm">
                  {singleChoice?.text}
                </span>
              </label>
            )
          })}
      </div>
    </div>
  ) : (
    <></>
  )
}
