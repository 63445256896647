import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {MessageBox} from 'app/components'
import {FormSectionPreview} from 'app/pages/settings/pages/forms/components'
import {Button, Loading} from 'app/common'
import {getTicketDetailsById, updateTicketActions} from 'redux-src'

interface TicketFormProps {
  selectedForm: {id: number; label: string; value: string}
  projectId: number
  projectTicketId: number
  isEdit?: boolean
}

export const TicketForm = ({
  selectedForm,
  isEdit,
  projectId,
  projectTicketId,
}: TicketFormProps) => {
  const {formsForDesktopPreview}: RT.FormBuilderReduxType = useSelector(
    (state: any) => state.formBuilder,
  )
  const {updateTicketLoading}: RT.TicktesReduxType = useSelector(
    (state: any) => state.tickets,
  )

  const dispatch = useDispatch()

  const formsPreview = useMemo(() => {
    return formsForDesktopPreview
  }, [formsForDesktopPreview])

  const [loading, setLoading] = useState<boolean>(true)

  const handleUpdateForm = () => {
    const finalFormFieldsElements = formsForDesktopPreview
      ?.flat(1)
      ?.filter((item) => item.elementType === 'data-entry')
      ?.map((itm) => ({id: itm.id, value: JSON.stringify(itm?.content?.value)}))

    const body = {
      fields: finalFormFieldsElements,
    }

    dispatch(
      updateTicketActions(projectId, projectTicketId, body, () => {
        dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
        // getTicketLogList()
      }),
    )
    // console.log({finalFormFieldsElements})
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 300)

    return () => {
      clearTimeout(timeout)
      setLoading(true)
    }
  }, [selectedForm])

  return loading ? (
    <Loading />
  ) : (
    <div className="px-20 py-8">
      <div className="py-4 font-bold text-gray-400 text-lg">
        {selectedForm?.label}
      </div>
      <div className="flex flex-col flex-1 gap-8 mb-20">
        {selectedForm?.id !== 0 && formsPreview?.length > 0 ? (
          formsPreview?.map((fm, index) => {
            return (
              <div
                key={`form-preview-item-container-${index}`}
                id={`form-preview-item-container-${index}`}
                className={`flex flex-row gap-8 w-full`}
              >
                {fm?.map((form) => {
                  return (
                    <FormSectionPreview
                      key={form.id}
                      formType={form?.formType}
                      id={form?.id}
                      type={'preview'}
                      isFieldsEditable={isEdit}
                    />
                  )
                })}
              </div>
            )
          })
        ) : (
          <MessageBox
            message={
              <div className="flex items-center">
                Select project form to see the preview.
              </div>
            }
          />
        )}

        {isEdit && (
          <div className="mt-10 flex items-center justify-end">
            <Button
              title="Update Form"
              onClick={handleUpdateForm}
              loading={updateTicketLoading}
            />
          </div>
        )}
      </div>
    </div>
  )
}
