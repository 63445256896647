import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useFormInput} from 'use-form-input'
import {useNavigation} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {IoAddOutline} from 'react-icons/io5'
import {MdFeedback} from 'react-icons/md'
import {FaCalendar, FaFilter} from 'react-icons/fa'
import {GoTasklist, GoListUnordered} from 'react-icons/go'
import {BsFillSendFill, BsSortDown, BsSortUp} from 'react-icons/bs'
import {BiImport} from 'react-icons/bi'

import {DynamicSearchSelect, MessageBox} from 'app/components'
import {
  Button,
  Checkbox,
  DateInput,
  Dropdown,
  Input,
  Modal,
  ToolTip,
  toast,
} from 'app/common'
import {
  GET_TICKET_ATTACHMENT_LIST,
  assignNewUserToTicket,
  createTicketCommentAction,
  createTickets,
  getAcceptedQuoteLineItemList,
  getProjectFormDetailsByProjectFormId,
  getProjectFormByIdAction,
  getProjectTicketList,
  getProjectUserList,
  getProjectUserGroupList,
  getTicketAttachmentList,
  getTicketDetailsById,
  getTicketLogListAction,
  removeAssignedEmployeeFromTicket,
  setMarkerCoordinateAction,
  updateFormFromApi,
  updateTicketActions,
  GET_TICKET_LOG_LIST,
  createSubTicket,
  updateSubTicket,
  getLineItemLabourElementList,
  createDaysWorkTicket,
  getLabourElementAction,
  getLabourGroupByIdAction,
} from 'redux-src'
import {getAddedAndDeletedIds} from 'utils'

import {TicketContext} from '../../ticket.page'
import {SetPlanPositionModel} from '../setPlanPositionModel'
import {JournalComponent} from '../journal'
import {SelectFloorPlan} from '../selectFloorPlan'
import {AddUsersFromGroup} from '../addUsersFormGroupModal'
import {HandleLabourElementModal} from '../handleLabourElementModal'
import {ProgressBar} from '../progressBar'
import {FeedbackModal, TicketAttachments, TicketForm} from './components'
import {getStatusChip} from '../planList/components'

interface CreateTicketModelProps {
  isModelVisible: boolean
  setIsModelVisible: React.Dispatch<React.SetStateAction<boolean>>
  edit?: boolean
  ticketIdFromSitevisit?: number
  projectIdFromInvoice?: number
  modalId?: string
  planCallback?: () => void
  isSubTicketModal?: boolean
  isSubTicketEdit?: boolean
  selectedLabourElements?: any
  parentTicketDetails?: Api.TicketDetailsIndividual['ticket_details']
}

export const CreateTicketModel = ({
  isModelVisible,
  setIsModelVisible,
  edit,
  ticketIdFromSitevisit,
  projectIdFromInvoice,
  modalId,
  planCallback,
  isSubTicketModal,
  isSubTicketEdit,
}: CreateTicketModelProps) => {
  const {projectDetail}: RT.ProjectReduxType = useSelector(
    (state: any) => state.project,
  )

  const {labourElementList, individualLabourGroup}: RT.LabourElementReduxType =
    useSelector((state: any) => state.labourElements)

  const [labourElementsOfItems, setLabourElementOfItems] = useState<{
    fromLabourElementGroupId?: any
    fromLabourElementId: any
  }>({fromLabourElementGroupId: undefined, fromLabourElementId: undefined})

  const {
    lineItemLabourElementList,
    acceptedQuoteLineItemList,
  }: RT.QuoteReduxType = useSelector((state: any) => state.quotes)

  const {forms}: RT.FormBuilderReduxType = useSelector(
    (state: any) => state.formBuilder,
  )

  const {
    projectDetailsByProjectFormIdData,
    projectFormByIdData,
  }: RT.FormsReduxType = useSelector((state: any) => state.forms)

  const {
    ticketDetailsById: projectTicketDetails,
    createTicketsLoading,
    updateTicketLoading,
    createSubTicketLoading,
    updateSubTicketLoading,
    createDaysWorkTicketLoading,
    parentTicketDetails: parentTicketDetailsFromRedux,
  }: RT.TicktesReduxType = useSelector((state: any) => state.tickets)

  const {projectPlanList: projectPlanListFromApi}: RT.ProjectPlanReduxType =
    useSelector((state: any) => state.projectPlan)

  const {
    projectTicketId: ticketIdFromTicketList,
    setProjectTicketId,
    setView,
  } = useContext(TicketContext)

  const projectTicketId = ticketIdFromSitevisit ?? ticketIdFromTicketList

  const {markerCoordinates, reportLayerCoordinates}: RT.TicktesReduxType =
    useSelector((state: any) => state.tickets)

  const {setCreateTicketModel} = useContext(TicketContext)

  const {params} = useNavigation()
  const {projectId: projectIdFromParams} = params as any

  const projectId = projectIdFromInvoice ?? projectIdFromParams

  // console.log({projectTicketId, projectId})

  const [isSetPlanModel, setModelVisible] = useState<boolean>(false)

  const [addUserModal, setAddUserModal] = useState<boolean>(false)
  const [quoteLineItems, setQuoteLineItems] = useState<any>()

  const [assets, setAssets] = useState<Array<{url: string; file: File}>>()
  const [ticketComment, setTicketComment] = useState<string>()

  const [isLabourElementModal, setLabourElementModal] = useState<boolean>(false)
  const [feedbackModalVisible, setFeedbackModal] = useState<boolean>(false)

  const [ticketData, {onChange, setValue, errors, modified, onSubmit, clear}] =
    useFormInput(
      {
        title: '',
        dueDate: '',
        formId: {id: 0, label: '', value: ''},
        selectedLineItem: {id: 0, label: '', value: 0},
        floorPlanId: {id: 0, label: '', value: 0},
        assignedUserIds: [],
        assignedGroupIds: {id: 0, label: '', value: 0},
        attachments: [],
        selectedItem: undefined,
      },
      () => {},
      (data) => {
        const errors: any = {}

        if (data?.title?.length === 0) {
          errors.title = `Ticket title can not be empty !!`
        }
        if (data?.dueDate?.length === 0) {
          errors.dueDate = `Please select the due date !!`
        }

        return errors
      },
    )

  const [journalFilter, journalHandler] = useFormInput<{
    type: Array<{
      id: number
      isChecked: boolean
      label: 'Activity Log' | 'Attachments' | 'Comments'
      value: 'activity log' | 'attachments' | 'comments'
    }>
    order: 'asc' | 'desc'
  }>({
    type: [
      {
        id: 1,
        isChecked: false,
        label: 'Comments',
        value: 'comments',
      },
      {
        id: 2,
        isChecked: false,
        label: 'Attachments',
        value: 'attachments',
      },
      {
        id: 3,
        isChecked: false,
        label: 'Activity Log',
        value: 'activity log',
      },
    ],
    order: 'desc',
  })

  const dispatch = useDispatch()

  //! FOR CLEARING RESIDUE OF PREVIOUS MODAL STATE
  useEffect(() => {
    onCloseModal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const remappedQuotesLineItemList = useCallback(() => {
    const remappedList = acceptedQuoteLineItemList?.map((lineItem) => {
      return {
        id: lineItem?.id,
        label: lineItem?.name,
        value: lineItem?.quote_item_id,
        quantity: lineItem?.quantity,
      }
    })

    setQuoteLineItems(remappedList)
  }, [acceptedQuoteLineItemList])

  const projectFormList = useMemo(() => {
    const remappedList = projectFormByIdData?.map((group) => {
      return {
        id: group?.project_form?.id,
        label: group?.project_form?.form_name,
        value: group?.project_form?.form_name,
      }
    })

    return remappedList
  }, [projectFormByIdData])

  const projectPlanList = useMemo(() => {
    const remappedProjectPlan = projectPlanListFromApi?.map((plan) => {
      return {
        id: plan?.project_plan_details?.id,
        label: plan?.project_plan_details?.name,
        value: plan?.project_plan_details?.id,
      }
    })

    return remappedProjectPlan
  }, [projectPlanListFromApi])

  const getProjectFormDetails = useCallback(
    (formId: number) => {
      dispatch(
        getProjectFormDetailsByProjectFormId(projectId, formId, (form) => {
          dispatch(updateFormFromApi(form))
        }),
      )
    },
    [dispatch, projectId],
  )

  const assignedUserToTicketHandle = () => {
    const {addedIds, deletedIds} = getAddedAndDeletedIds(
      projectTicketDetails?.assigned_employees?.map(
        (user) => user?.user_details?.id,
      ),
      ticketData?.assignedUserIds?.map((item) => item?.id),
    )

    const body: any = {
      assignedProjectUserIds:
        ticketData?.assignedUserIds?.map((item) => item?.id) ?? undefined,
      assignedProjectUserGroupId: ticketData?.assignedGroupIds?.id ?? undefined,
    }

    if (edit && (addedIds?.length > 0 || deletedIds?.length > 0)) {
      if (addedIds?.length > 0) {
        const addedBody = {
          assignedProjectUserIds: addedIds,
          assignedProjectUserGroupId: ticketData?.assignedGroupIds?.id,
        }

        dispatch(
          assignNewUserToTicket(
            projectId,
            projectTicketId,
            addedBody,
            () => {},
          ),
        )
      }

      if (deletedIds?.length > 0) {
        const deletedBody = {
          assignedUserIds: deletedIds,
        }

        dispatch(
          removeAssignedEmployeeFromTicket(
            projectId,
            projectTicketId,
            deletedBody,
            () => {},
          ),
        )
      }
      return
    }

    dispatch(assignNewUserToTicket(projectId, projectTicketId, body, () => {}))
  }

  const onCreateTicketHandle = (event: any) => {
    event.preventDefault()

    const {
      assignedUserIds,
      dueDate,
      floorPlanId,
      formId,
      selectedLineItem,
      title,
    } = ticketData

    if (
      formId?.id === 0 ||
      title?.length === 0 ||
      selectedLineItem?.id === 0 ||
      floorPlanId?.id === 0 ||
      dueDate?.length === 0
    ) {
      onSubmit(event)
      return toast.error('Please fill all the required fields !!')
    }

    if (
      !!!markerCoordinates &&
      !!!markerCoordinates?.x &&
      !!!markerCoordinates?.y
    ) {
      return toast.error(
        'Please set the plan position of selected plan viewer !!',
      )
    }

    const formTemplateFields = forms.map((form) => {
      return {
        fieldName:
          projectDetailsByProjectFormIdData?.project_form_details?.form_name,
        properties: form,
        formFieldId: form?.id,
        visibleTo: form.visibleTo,
        editableBy: form.editableBy,
        dataTypeId: form.dataTypeId,
        position: form.position,
      }
    })

    if (!!edit) {
      const body = {
        title: title,
        projectPlanId: Number(floorPlanId?.id),
        layerCoordinates: [
          Number(markerCoordinates?.x ?? 0),
          Number(markerCoordinates?.y ?? 0),
        ],
        reportLayerCoordinates: [
          Number(reportLayerCoordinates?.x ?? 0),
          Number(reportLayerCoordinates?.y ?? 0),
        ],
        dueDate: dueDate,
      }

      assignedUserToTicketHandle()

      dispatch(
        updateTicketActions(projectId, projectTicketId, body, () => {
          dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          dispatch(
            getProjectTicketList(projectId, undefined, () => {
              planCallback?.()
            }),
          )
          getTicketLogList()
        }),
      )

      return
    }

    const templateField = JSON.stringify(formTemplateFields)

    const ticketFormData = new FormData()

    ticketFormData?.append('title', title)
    ticketFormData?.append('dueDate', dueDate)
    ticketFormData?.append('projectFormId', String(formId?.id))
    ticketFormData?.append('projectPlanId', String(floorPlanId?.id))
    ticketFormData?.append('quoteLineItemId', String(selectedLineItem?.id))
    ticketFormData?.append('fields', templateField)
    ticketFormData?.append('layerCoordinates[0]', String(markerCoordinates?.x))
    ticketFormData?.append('layerCoordinates[1]', String(markerCoordinates?.y))
    ticketFormData?.append(
      'reportLayerCoordinates[0]',
      String(reportLayerCoordinates?.x ?? 0),
    )
    ticketFormData?.append(
      'reportLayerCoordinates[1]',
      String(reportLayerCoordinates?.y ?? 0),
    )
    assignedUserIds?.map((userId, index) => {
      return ticketFormData?.append(
        `assignedProjectUserIds[${index}]`,
        String(userId?.id),
      )
    })

    assets?.map((item) => {
      return ticketFormData.append('file', item?.file)
    })

    dispatch(
      createTickets(projectId, ticketFormData, () => {
        onCloseModal()
        setIsModelVisible(false)
        dispatch(
          getProjectTicketList(projectId, undefined, () => {
            planCallback?.()
            setView('list')
          }),
        )
      }),
    )
  }

  const onCreateDaysWorkTicket = (event: any) => {
    event.preventDefault()

    const {assignedUserIds, dueDate, floorPlanId, formId, title, selectedItem} =
      ticketData

    if (!edit && !isSubTicketEdit) {
      if (
        formId?.id === 0 ||
        title?.length === 0 ||
        selectedItem === undefined ||
        floorPlanId?.id === 0 ||
        dueDate?.length === 0
      ) {
        onSubmit(event)
        return toast.error('Please fill all the required fields hello !!')
      }
    } else {
      if (
        formId?.id === 0 ||
        title?.length === 0 ||
        floorPlanId?.id === 0 ||
        dueDate?.length === 0
      ) {
        onSubmit(event)
        return toast.error('Please fill all the required fields hello !!')
      }
    }

    const formTemplateFields = forms.map((form) => {
      return {
        fieldName:
          projectDetailsByProjectFormIdData?.project_form_details?.form_name,
        properties: form,
        formFieldId: form?.id,
        visibleTo: form.visibleTo,
        editableBy: form.editableBy,
        dataTypeId: form.dataTypeId,
        position: form.position,
      }
    })

    if (!!edit) {
      const body = {
        title: title,
        projectPlanId: Number(floorPlanId?.id),
        layerCoordinates:
          !!markerCoordinates?.x && !!markerCoordinates?.y
            ? [
                Number(markerCoordinates?.x ?? 0),
                Number(markerCoordinates?.y ?? 0),
              ]
            : undefined,
        reportLayerCoordinates:
          !!reportLayerCoordinates?.x && !!reportLayerCoordinates?.y
            ? [
                Number(reportLayerCoordinates?.x ?? 0),
                Number(reportLayerCoordinates?.y ?? 0),
              ]
            : undefined,
        dueDate: dueDate,
      }

      assignedUserToTicketHandle()

      dispatch(
        updateTicketActions(projectId, projectTicketId, body, () => {
          dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
          dispatch(
            getProjectTicketList(projectId, undefined, () => {
              planCallback?.()
            }),
          )
          getTicketLogList()
        }),
      )

      return
    }

    const templateField = JSON.stringify(formTemplateFields)

    const ticketFormData = new FormData()

    ticketFormData?.append('title', title)
    ticketFormData?.append('dueDate', dueDate)
    ticketFormData?.append('projectFormId', String(formId?.id))
    ticketFormData?.append('itemId', String(selectedItem?.id))
    ticketFormData?.append('projectPlanId', String(floorPlanId?.id))
    ticketFormData?.append('fields', templateField)

    if (!!markerCoordinates?.x && !!markerCoordinates?.y) {
      ticketFormData?.append(
        'layerCoordinates[0]',
        String(markerCoordinates?.x),
      )
      ticketFormData?.append(
        'layerCoordinates[1]',
        String(markerCoordinates?.y),
      )
    }

    ticketFormData?.append(
      'reportLayerCoordinates[0]',
      String(reportLayerCoordinates?.x ?? 0),
    )
    ticketFormData?.append(
      'reportLayerCoordinates[1]',
      String(reportLayerCoordinates?.y ?? 0),
    )
    assignedUserIds?.map((userId, index) => {
      return ticketFormData?.append(
        `assignedProjectUserIds[${index}]`,
        String(userId?.id),
      )
    })

    assets?.map((item) => {
      return ticketFormData.append('file', item?.file)
    })

    dispatch(
      createDaysWorkTicket(projectId, ticketFormData, () => {
        onCloseModal()
        setIsModelVisible(false)
        dispatch(
          getProjectTicketList(projectId, undefined, () => {
            planCallback?.()
            setView('list')
          }),
        )
      }),
    )
  }

  const onCreateSubTicketHandle = (event: any) => {
    event.preventDefault()

    const {
      assignedUserIds,
      dueDate,
      floorPlanId,
      formId,
      selectedLineItem,
      title,
    } = ticketData

    if (
      formId?.id === 0 ||
      title?.length === 0 ||
      selectedLineItem?.id === 0 ||
      floorPlanId?.id === 0 ||
      dueDate?.length === 0
    ) {
      onSubmit(event)
      return toast.error('Please fill all the required fields !!')
    }

    const formTemplateFields = forms.map((form) => {
      return {
        fieldName:
          projectDetailsByProjectFormIdData?.project_form_details?.form_name,
        properties: form,
        formFieldId: form?.id,
        visibleTo: form.visibleTo,
        editableBy: form.editableBy,
        dataTypeId: form.dataTypeId,
        position: form.position,
      }
    })

    if (!!isSubTicketEdit) {
      const body = {
        title: title,
        dueDate: dueDate,
      }

      assignedUserToTicketHandle()

      dispatch(
        updateSubTicket(
          projectId,
          projectTicketDetails?.parent_ticket_details?.id,
          projectTicketId,
          body,
          () => {
            dispatch(getTicketDetailsById(projectId, projectTicketId, () => {}))
            dispatch(
              getProjectTicketList(projectId, undefined, () => {
                planCallback?.()
              }),
            )
            getTicketLogList()
          },
        ),
      )

      return
    }

    const templateField = JSON.stringify(formTemplateFields)

    const ticketFormData = new FormData()

    ticketFormData?.append('title', title)
    ticketFormData?.append('dueDate', dueDate)
    ticketFormData?.append('projectFormId', String(formId?.id))

    ticketFormData?.append('fields', templateField)

    assignedUserIds?.map((userId, index) => {
      return ticketFormData?.append(
        `assignedProjectUserIds[${index}]`,
        String(userId?.id),
      )
    })

    assets?.map((item) => {
      return ticketFormData.append('file', item?.file)
    })

    parentTicketDetailsFromRedux?.selectedLabourElements?.map(
      (item: any, index: number) => {
        return ticketFormData.append(
          `ticketLabourElementIds[${index}]`,
          item?.id,
        )
      },
    )

    dispatch(
      createSubTicket(
        projectId,
        parentTicketDetailsFromRedux?.parentTicketDetails?.id,
        ticketFormData,
        () => {
          onCloseModal()
          setIsModelVisible(false)
          setCreateTicketModel(false)
          dispatch(
            getProjectTicketList(projectId, undefined, () => {
              planCallback?.()
              setView('list')
            }),
          )
        },
      ),
    )
  }

  const handleAllOnClickFunctions = (
    event: any,
    condition: {
      isSubTicket: boolean
      edit: boolean
      isDaysWorkProject: boolean
    },
  ) => {
    switch (true) {
      case condition.isSubTicket === true:
        return onCreateSubTicketHandle(event)
      case condition.isDaysWorkProject === true:
        return onCreateDaysWorkTicket(event)
      case condition.isSubTicket === false:
        return onCreateTicketHandle(event)
      default:
        return null
    }
  }

  const ticketLoading = useCallback(
    (condition: {
      isSubTicket: boolean
      edit: boolean
      isSubTicketEdit: boolean
      isDaysWorkProject: boolean
    }) => {
      switch (true) {
        case condition.isSubTicket === true:
          return condition.isSubTicketEdit
            ? updateSubTicketLoading
            : createSubTicketLoading
        case condition.isDaysWorkProject === true:
          return createDaysWorkTicketLoading
        case condition.isSubTicket === false:
          return condition.edit ? updateTicketLoading : createTicketsLoading
        default:
          return null
      }
    },
    [
      createDaysWorkTicketLoading,
      createSubTicketLoading,
      createTicketsLoading,
      updateSubTicketLoading,
      updateTicketLoading,
    ],
  )

  const fetchTicketDetails = useCallback(() => {
    !!projectId &&
      !!projectTicketId &&
      dispatch(getTicketDetailsById(projectId, projectTicketId))
  }, [dispatch, projectId, projectTicketId])

  useEffect(() => {
    fetchTicketDetails()
  }, [fetchTicketDetails])

  useEffect(() => {
    if (edit && !!projectTicketDetails) {
      setValue('title', projectTicketDetails?.ticket_details?.title)
      setValue('dueDate', projectTicketDetails?.ticket_details?.due_date)
      setValue(
        'selectedLineItem',
        quoteLineItems?.find(
          (item: any) =>
            item?.id ===
            projectTicketDetails?.ticket_details?.quote_line_item_id,
        ),
      )

      setValue(
        'formId',
        projectFormList?.find(
          (form) =>
            form?.id === projectTicketDetails?.ticket_details?.project_form_id,
        ),
      )
      setValue(
        'floorPlanId',
        projectPlanList?.find(
          (plan) =>
            plan?.id === projectTicketDetails?.ticket_details?.project_plan_id,
        ),
      )

      setValue('assignedUserIds', () => {
        const prePopulatedData = projectTicketDetails?.assigned_employees?.map(
          (user) => {
            return {
              id: user?.user_details?.id,
              label: user?.user_details?.display_name,
              value: user?.user_details?.id,
            }
          },
        )

        return prePopulatedData ?? null
      })

      const finalFormFields: Api.IndividualFormById = {
        form_name: ticketData?.formId?.label,
        fields: projectTicketDetails?.form_fields?.map((formField) => {
          return {
            ...formField.details,
            properties: {
              ...formField?.details?.properties,
              content: {
                ...formField?.details?.properties?.content,
                value: !!formField?.details?.value
                  ? JSON.parse(formField?.details?.value)
                  : formField?.details?.properties?.content?.value,
              },
            },
            asset_details: formField.details.properties.asset_details,
            data_type: 'name',
            assets: formField?.assets,
          }
        }),
      }

      dispatch(updateFormFromApi(finalFormFields))

      if (projectTicketDetails?.ticket_details?.layer_coordinates !== null) {
        dispatch(
          setMarkerCoordinateAction(
            {
              x: projectTicketDetails?.ticket_details?.layer_coordinates[0],
              y: projectTicketDetails?.ticket_details?.layer_coordinates[1],
            },
            {
              x: projectTicketDetails?.ticket_details
                ?.report_layer_coordinates[0],
              y: projectTicketDetails?.ticket_details
                ?.report_layer_coordinates[1],
            },
          ),
        )
      }
    }

    if (
      !!parentTicketDetailsFromRedux &&
      isSubTicketModal &&
      !isSubTicketEdit
    ) {
      setValue(
        'selectedLineItem',
        quoteLineItems?.find(
          (item: any) =>
            item?.id ===
            parentTicketDetailsFromRedux?.parentTicketDetails
              ?.quote_line_item_id,
        ),
      )

      setValue(
        'floorPlanId',
        projectPlanList?.find(
          (plan) =>
            plan?.id ===
            parentTicketDetailsFromRedux?.parentTicketDetails?.project_plan_id,
        ),
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, projectTicketDetails, parentTicketDetailsFromRedux, quoteLineItems])

  const onCreateTicketComment = (cb: () => void) => {
    if (!!!ticketComment || ticketComment.length < 0)
      return toast.error('Comment can not be empty !!')

    const commentBody = {
      message: ticketComment ?? undefined,
    }

    dispatch(
      createTicketCommentAction(projectId, projectTicketId, commentBody, () => {
        getTicketLogList()

        setTicketComment('')
        cb()
      }),
    )
  }

  useEffect(() => {
    if (!!projectDetail?.project_details?.accepted_quote_id) {
      dispatch(
        getAcceptedQuoteLineItemList(
          projectId,
          projectDetail?.project_details?.accepted_quote_id,
          () => {},
        ),
      )
    }
  }, [
    dispatch,
    projectDetail?.project_details?.accepted_quote_id,
    projectId,
    parentTicketDetailsFromRedux?.parentTicketDetails,
  ])

  useEffect(() => {
    !!projectId && dispatch(getProjectFormByIdAction(projectId))
  }, [dispatch, projectId])

  useEffect(() => {
    dispatch(getProjectUserGroupList(projectId, 1, 100, '', () => {}))
  }, [dispatch, projectId])

  useEffect(() => {
    !!projectTicketId &&
      dispatch(getTicketAttachmentList(projectId, projectTicketId))
  }, [dispatch, projectId, projectTicketId])

  useEffect(() => {
    dispatch(getProjectUserList(projectId, 1, 100, '', () => {}))
  }, [dispatch, projectId])

  useEffect(() => {
    if (ticketData?.selectedItem?.labourElementId) {
      dispatch(getLabourElementAction())
    }
  }, [dispatch, ticketData?.selectedItem?.labourElementId])

  useEffect(() => {
    if (ticketData?.selectedItem?.labourElementGroupId) {
      dispatch(
        getLabourGroupByIdAction(
          ticketData?.selectedItem?.labourElementGroupId,
        ),
      )
    }
  }, [dispatch, ticketData?.selectedItem?.labourElementGroupId])

  const getTicketLogList = useCallback(() => {
    if (projectTicketId) {
      const journalType = Array.isArray(journalFilter?.type)
        ? journalFilter?.type?.filter((filter) => filter.isChecked)
        : []

      dispatch(
        getTicketLogListAction(
          1,
          10,
          projectId,
          projectTicketId,
          journalFilter?.order,
          journalType?.length === 0
            ? undefined
            : journalType?.map((type) => type.value)?.join(','),
        ),
      )
    }
  }, [
    dispatch,
    journalFilter?.order,
    journalFilter?.type,
    projectId,
    projectTicketId,
  ])

  useEffect(() => {
    getTicketLogList()
  }, [getTicketLogList])

  useEffect(() => {
    remappedQuotesLineItemList()
  }, [remappedQuotesLineItemList])

  const onCloseModal = (clearData: boolean = true) => {
    if (clearData) {
      clear()
      setProjectTicketId(undefined)
    }
    dispatch(updateFormFromApi(undefined))
    setAssets(undefined)
    dispatch({
      type: GET_TICKET_ATTACHMENT_LIST.SUCCESS,
      payload: {total: 0, rows: []},
    })

    dispatch({type: GET_TICKET_LOG_LIST.SUCCESS, payload: {total: 0, rows: []}})
    setValue('selectedLineItem', {id: 0, label: '', value: undefined})

    dispatch(setMarkerCoordinateAction(undefined))

    journalHandler?.setValue('type', [
      {
        id: 1,
        isChecked: false,
        label: 'Comments',
        value: 'comments',
      },
      {
        id: 2,
        isChecked: false,
        label: 'Attachments',
        value: 'attachments',
      },
      {
        id: 3,
        isChecked: false,
        label: 'Activity Log',
        value: 'activity log',
      },
    ])

    journalHandler?.setValue('order', 'desc')
  }

  const fetchLinItemLabourElementList = useCallback(() => {
    if (
      ticketData.selectedLineItem?.value !== 0 &&
      !!ticketData.selectedLineItem?.id &&
      !!projectDetail?.project_details?.accepted_quote_id
    ) {
      dispatch(
        getLineItemLabourElementList(
          projectId,
          projectDetail?.project_details?.accepted_quote_id,
          ticketData.selectedLineItem?.value,
          ticketData.selectedLineItem?.id,
        ),
      )
    }
  }, [
    dispatch,
    projectDetail?.project_details?.accepted_quote_id,
    projectId,
    ticketData.selectedLineItem?.id,
    ticketData.selectedLineItem?.value,
  ])

  useEffect(() => {
    fetchLinItemLabourElementList()
  }, [fetchLinItemLabourElementList])

  useEffect(() => {
    if (ticketData?.selectedItem?.labourElementId && !!labourElementList) {
      const element = labourElementList?.find(
        (item) =>
          item?.labour_element_details?.id ===
          ticketData?.selectedItem?.labourElementId,
      )

      setLabourElementOfItems({
        fromLabourElementId: [
          {
            quote_labour_element_detail: {
              id: element?.labour_element_details?.id,
              name: element?.labour_element_details?.name,
              time_mins: element?.labour_element_details?.time_mins,
              unit_name: element?.labour_element_details?.unit_name,
              number_per_hr: element?.labour_element_details?.number_per_hr,
              labour_element_id: element?.labour_element_details?.id,
            },
          },
        ],
        fromLabourElementGroupId: undefined,
      })
    }

    if (
      ticketData?.selectedItem?.labourElementGroupId &&
      !!individualLabourGroup
    ) {
      const elementList = individualLabourGroup?.labour_elements?.map(
        (item) => {
          return {
            quote_labour_element_detail: {
              id: item?.id,
              name: item?.name,
              time_mins: item?.time_mins,
              unit_name: item?.unit_name,
              number_per_hr: item?.number_per_hr,
              labour_element_id: item?.id,
            },
          }
        },
      )

      setLabourElementOfItems({
        fromLabourElementId: undefined,
        fromLabourElementGroupId: elementList,
      })
    }
  }, [
    individualLabourGroup,
    individualLabourGroup?.labour_elements,
    labourElementList,
    ticketData?.selectedItem?.labourElementGroupId,
    ticketData?.selectedItem?.labourElementId,
  ])

  const onChangeJournalComment = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setTicketComment(e.target.value)
  }

  return (
    <Modal
      key={modalId}
      id={modalId}
      isActive={isModelVisible}
      toggleModal={setIsModelVisible}
      onClose={onCloseModal}
      title={
        <div className="flex items-center justify-between">
          {isSubTicketModal ? (
            <div className="flex-1 text-black font-bold">
              {isSubTicketEdit ? 'Update Sub-Ticket' : 'Create Sub-Ticket'}
            </div>
          ) : (
            <div className="flex-1 text-black font-bold">
              {edit ? 'Update Ticket' : 'Create Ticket'}
            </div>
          )}
          <div className="flex flex-1 w-full gap-10 justify-end items-center">
            {isSubTicketModal && (
              <span className="bg-red-400 px-12 py-4 rounded-full uppercase text-white text-[10px]">
                Sub-Ticket
              </span>
            )}
            {(isSubTicketModal || edit) &&
              projectTicketDetails?.ticket_details?.status !== 'completed' && (
                <Button
                  buttonColor="text-sm text-white bg-orange-700 hover:bg-orange-600"
                  onClick={() => {
                    setFeedbackModal(true)
                  }}
                >
                  <div className="flex items-center justify-between gap-10">
                    <MdFeedback />
                    Leave Feedback
                  </div>
                </Button>
              )}
            {(isSubTicketModal || edit) && (
              <div className="">
                {getStatusChip(
                  projectTicketDetails?.ticket_details?.status as
                    | 'open'
                    | 'in progress'
                    | 'feedback'
                    | 'completed',
                )}
              </div>
            )}
            {edit && (
              <div className="flex items-center justify-center">
                <ProgressBar
                  progressPercentage={
                    projectTicketDetails?.ticket_details?.progress_percent
                  }
                  width={'100px'}
                />
              </div>
            )}
          </div>
        </div>
      }
      size={'lg'}
    >
      <div
        className={`flex flex-col p-20 ${
          edit && projectTicketDetails?.ticket_details?.status === 'completed'
            ? 'form-disable'
            : ''
        }`}
      >
        <div className="flex w-full gap-18 mt-[-10px]">
          <div className="flex flex-1 flex-col gap-10">
            <div className="flex flex-col flex-1 gap-6 w-full">
              <div className="text-sm font-bold">Title</div>
              <Input
                type="text"
                name="title"
                placeholder="Ticket title"
                onChange={onChange}
                value={ticketData?.title}
                error={errors?.title && modified?.title}
                containerClass="relative w-full form-group"
              />
            </div>
            <div className="flex flex-col flex-1">
              <div className="flex items-center justify-between text-sm font-bold">
                {projectDetail?.project_details?.project_type === 'Quote' ||
                edit ||
                isSubTicketEdit ? (
                  <span>
                    Select{' '}
                    {projectDetail?.project_details?.project_type === 'Quote'
                      ? 'Quote Line'
                      : ''}{' '}
                    Item
                  </span>
                ) : (
                  <span>Select Item</span>
                )}
                {(ticketData?.selectedLineItem?.id !== 0 ||
                  !!ticketData?.selectedItem) && (
                  <ToolTip
                    text={
                      edit
                        ? `Handle ${
                            projectDetail?.project_details?.project_type ===
                            'Quote'
                              ? 'Quote Item'
                              : ''
                          } Labour Elements`
                        : `View ${
                            projectDetail?.project_details?.project_type ===
                            'Quote'
                              ? 'Quote Item'
                              : ''
                          } Labour Elements`
                    }
                    top
                  >
                    <div
                      className="cursor-pointer pr-8 pointer-events-auto"
                      onClick={() => {
                        setLabourElementModal((prev) => !prev)
                      }}
                    >
                      {edit ? (
                        <GoTasklist
                          size={20}
                          strokeWidth="1"
                          className="text-blue-400 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer"
                        />
                      ) : (
                        <GoListUnordered
                          size={20}
                          strokeWidth="1"
                          className="text-blue-400 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer"
                        />
                      )}
                    </div>
                  </ToolTip>
                )}
              </div>
              <div className="w-full mt-8 flex gap-10 items-center justify-between">
                {projectDetail?.project_details?.project_type === 'Quote' ||
                edit ||
                isSubTicketModal ? (
                  <DynamicSearchSelect
                    actionUrl={`/projects/${projectId}/quotes/${projectDetail?.project_details?.accepted_quote_id}/line-item-list`}
                    placeholder="Search quote line item"
                    value={
                      ticketData?.selectedLineItem?.id === 0
                        ? undefined
                        : ticketData?.selectedLineItem
                    }
                    remapOptions={(
                      data: RT.QuoteReduxType['acceptedQuoteLineItemList'],
                    ) => {
                      return data?.map((lineItem) => {
                        return {
                          id: lineItem?.id,
                          label: `${lineItem?.name}`,
                          value: lineItem?.quote_item_id,
                          quantity: lineItem?.quantity,
                        }
                      })
                    }}
                    onChangeValue={(value) => {
                      setValue('selectedLineItem', value)
                    }}
                    formatOptionLabel={(data: any) => {
                      return (
                        <div className="flex gap-8 items-center justify-between">
                          <p>{data.label}</p>
                          <p>[{data?.quantity}]</p>
                        </div>
                      )
                    }}
                    isDisabled={edit || isSubTicketModal}
                  />
                ) : (
                  <DynamicSearchSelect
                    actionUrl={`/item`}
                    placeholder="Search item"
                    value={ticketData?.selectedItem}
                    remapOptions={(data: Array<Api.ItemIndividual>) => {
                      return data?.map((item) => {
                        return {
                          id: item?.item_details?.id,
                          label: item?.item_details?.name,
                          value: item?.item_details?.id,
                          labourElementId:
                            item?.item_details?.labour_element_id,
                          labourElementGroupId:
                            item?.item_details?.labour_element_group_id,
                        }
                      })
                    }}
                    onChangeValue={(value) => {
                      setValue('selectedItem', value)
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div className="flex flex-col flex-1 gap-6 select-none"> */}
            <DateInput
              prepend={<FaCalendar />}
              label="Due Date"
              name="dueDate"
              onChange={(date) => {
                setValue('dueDate', date)
              }}
              value={ticketData?.dueDate}
              placeholder="Due date"
            />

            {/* </div> */}
            <div className="flex">
              <div className="flex flex-col gap-12 flex-1">
                <div className="flex flex-col gap-8 pt-6">
                  <div className="flex justify-between items-center">
                    <div className="text-sm text-black font-bold">
                      Assigned User To Ticket
                    </div>
                    <ToolTip text="Import users from groups !!" top>
                      <div className="flex flex-col items-center justify-end pr-8">
                        <div
                          className="text-sm flex align-center"
                          onClick={() => {
                            setAddUserModal(true)
                          }}
                        >
                          <BiImport
                            size={22}
                            className="text-blue-400 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer"
                          />
                        </div>
                      </div>
                    </ToolTip>
                  </div>
                  <DynamicSearchSelect
                    actionUrl={`/projects/${projectId}/users`}
                    placeholder="Search for an existing project users"
                    remapOptions={(data: Api.ProjectUserListIndividual[]) => {
                      return data?.map(({user_details}) => {
                        return {
                          id: user_details?.id,
                          label: `${user_details?.display_name} ${user_details?.lastname}`,
                          value: user_details?.id,
                        }
                      })
                    }}
                    multiValue={ticketData?.assignedUserIds ?? undefined}
                    onMultiChangeValue={(value) => {
                      setValue('assignedUserIds', () =>
                        value?.map((user) => user),
                      )
                    }}
                    isMulti
                  />
                </div>
              </div>
            </div>
            {projectTicketDetails?.ticket_details?.project_form_id !== null ||
            !edit ||
            (!!isSubTicketModal && !!!isSubTicketEdit) ? (
              <div className="flex flex-col gap-12 mt-8">
                <div className="flex flex-col gap-8">
                  <div className="text-sm text-black font-bold">
                    Select project forms
                  </div>
                  <DynamicSearchSelect
                    actionUrl={`/forms/project-forms/${projectId}`}
                    placeholder="Search for an existing project forms"
                    value={
                      ticketData?.formId?.id === 0
                        ? undefined
                        : ticketData?.formId
                    }
                    remapOptions={(data: Api.ProjectFormIndividual[]) => {
                      return data?.map(({project_form}) => {
                        return {
                          id: project_form?.id,
                          label: project_form?.form_name,
                          value: project_form?.form_name,
                        }
                      })
                    }}
                    onChangeValue={(value) => {
                      dispatch({
                        type: 'UPDATE_FORMS_FOR_PREVIEW',
                        payload: {forms: undefined},
                      })
                      getProjectFormDetails(value?.id)
                      value && setValue('formId', value)
                    }}
                    isDisabled={edit}
                  />
                </div>
              </div>
            ) : (
              <div className="text-sm text-black font-bold py-20">
                Ticket Form
              </div>
            )}
          </div>
          <div className="flex flex-1 flex-col gap-6 px-10 py-8 border border-solid border-1 border-gray-200 rounded-sm min-h-[100px]">
            <div className="text-sm font-bold text-black">Plan Viewer</div>
            <div className="w-[full] mt-8 flex gap-10 items-center">
              <DynamicSearchSelect
                actionUrl={`/projects/${projectId}/plans/`}
                placeholder="Search floor plan"
                value={
                  ticketData?.floorPlanId?.id === 0
                    ? undefined
                    : ticketData?.floorPlanId
                }
                remapOptions={(data: Api.ProjectPlanIndividual[]) => {
                  return data?.map((floorPlan) => {
                    return {
                      id: floorPlan?.project_plan_details?.id,
                      label: floorPlan?.project_plan_details?.name,
                      value: floorPlan?.project_plan_details?.id,
                    }
                  })
                }}
                onChangeValue={(value) => {
                  setValue('floorPlanId', value)

                  dispatch(setMarkerCoordinateAction(undefined))
                }}
                isDisabled={isSubTicketModal}
              />
            </div>
            <div
              className={`w-full rounded-sm flex gap-4 px-8 items-center justify-center py-6 border border-1 border-solid border-gray-200 hover:bg-gray-100 bg-white ${
                isSubTicketModal || ticketData?.floorPlanId?.id === 0
                  ? 'cursor-not-allowed text-gray-300'
                  : 'cursor-pointer text-black'
              }`}
              onClick={() => {
                if (ticketData?.floorPlanId?.id === 0 || isSubTicketModal) {
                  return
                }
                setModelVisible((prev) => !prev)
              }}
            >
              <IoAddOutline size={18} />
              <span>Set plan position</span>
            </div>
            {!!markerCoordinates?.x && !!markerCoordinates?.y ? (
              <span
                onClick={() => {
                  !isSubTicketModal &&
                    projectTicketDetails?.ticket_details?.status !==
                      'completed' &&
                    setModelVisible(true)
                }}
              >
                <SelectFloorPlan
                  floorPlanId={ticketData?.floorPlanId?.id}
                  containerHeight={210}
                  disableZoom
                  ticketStatus={
                    (projectTicketDetails?.ticket_details?.status ?? 'open') as
                      | 'open'
                      | 'in progress'
                      | 'feedback'
                      | 'completed'
                  }
                />
              </span>
            ) : (
              <MessageBox
                message={
                  <div className="flex gap-10">
                    Please set a plan position for the selected floor plan.
                  </div>
                }
              />
            )}
          </div>
        </div>

        <div className="flex mt-10 gap-16">
          <div className="flex flex-[2] w-full pointer-events-auto flex-col bg-gray-100 rounded-sm overflow-y-auto h-[800px]">
            <TicketForm
              projectId={projectId}
              projectTicketId={projectTicketId}
              selectedForm={ticketData?.formId}
              isEdit={edit}
            />
          </div>
          <div className="flex-1 pointer-events-auto">
            <TicketAttachments
              edit={edit}
              isSubTicketEdit={isSubTicketEdit}
              projectTicketId={projectTicketId}
              addDisabled={
                projectTicketDetails?.ticket_details?.status === 'completed' &&
                edit
              }
              {...{getTicketLogList, setAssets, assets}}
            />
            {edit && (
              <div className="flex flex-col gap-6 border border-1 border-solid border-gray-200 relative rounded-sm h-[350px] mt-10 px-10 py-10">
                <div className="flex flex-row flex-nowrap justify-between items-center pointer-events-auto">
                  <div className="text-sm text-black font-bold">Journal</div>
                  <Dropdown
                    portalId="modal-portal"
                    triggerElement={
                      <div className="flex items-center justify-center cursor-pointer p-4 hover:bg-gray-100 rounded-sm">
                        <FaFilter />
                      </div>
                    }
                    triggerToggle
                    placement="bottomright"
                    inDismiss={false}
                    outDismiss={false}
                  >
                    <div className="bg-white flex flex-col gap-20 p-20 shadow-[gray] shadow-lg rounded-md">
                      <div className="flex flex-col gap-10">
                        <div className="font-bold">Show</div>
                        {Array.isArray(journalFilter?.type) &&
                          journalFilter?.type?.map((item) => (
                            <Checkbox
                              key={`${item.value}-journal`}
                              className="ml-8"
                              labelClassName="ml-8"
                              dataId={`${item.value}-journal`}
                              isChecked={item.isChecked}
                              value={item.isChecked}
                              label={item.label}
                              onChange={() => {
                                journalHandler.setValue(
                                  'type',
                                  journalFilter?.type?.map((filter) => {
                                    return {
                                      ...filter,
                                      isChecked:
                                        filter.id === item.id
                                          ? !filter.isChecked
                                          : filter.isChecked,
                                    }
                                  }),
                                )
                              }}
                            />
                          ))}
                      </div>
                      <div className="flex flex-col">
                        <div className="font-bold mb-6">Order</div>
                        <div
                          className={`flex flex-row rounded-sm transition-all duration-200 ease-out flex-nowrap justify-start items-center gap-10 px-8 py-6 cursor-pointer ${
                            journalFilter?.order === 'asc'
                              ? 'bg-blue-150 hover:bg-blue-200'
                              : 'hover:bg-gray-100'
                          }`}
                          onClick={() =>
                            journalHandler.setValue('order', 'asc')
                          }
                        >
                          <BsSortUp />
                          Date Ascending
                        </div>
                        <div
                          className={`flex flex-row rounded-sm transition-all duration-200 ease-out flex-nowrap justify-start items-center gap-10 px-8 py-6 cursor-pointer ${
                            journalFilter?.order === 'desc'
                              ? 'bg-blue-150 hover:bg-blue-200'
                              : 'hover:bg-gray-100'
                          }`}
                          onClick={() =>
                            journalHandler.setValue('order', 'desc')
                          }
                        >
                          <BsSortDown />
                          Date Descending
                        </div>
                      </div>

                      <div className="flex flex-col gap-8">
                        <div className="font-bold mb-6">Index</div>
                        <div
                          className="px-6 border
                        border-solid border-l-2 border-blue-300 italic"
                        >
                          Activity
                        </div>
                        <div
                          className="px-6 border
                        border-solid border-l-2 border-yellow italic"
                        >
                          Feedback
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
                <JournalComponent {...{journalFilter}} />
                <JournalTextarea
                  {...{
                    onChangeJournalComment,
                    onCreateTicketComment,
                    ticketComment,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end gap-10 px-20 py-8 border border-t-1 border-gray-200 mt-10">
          <Button
            title="Cancel"
            onClick={() => {
              onCloseModal()
              setIsModelVisible(false)
            }}
            buttonColor="text-blue-400 bg-blue-100 hover:bg-blue-150 pointer-events-auto"
          />
          <div>
            <Button
              title={isSubTicketEdit || edit ? 'Update' : 'Create'}
              loading={ticketLoading({
                isSubTicket: !!isSubTicketModal,
                edit: edit,
                isSubTicketEdit: isSubTicketEdit,
                isDaysWorkProject: !!(
                  projectDetail?.project_details?.project_type === 'Days Work'
                ),
              })}
              onClick={(e: any) => {
                handleAllOnClickFunctions(e, {
                  isSubTicket: !!isSubTicketModal,
                  edit: edit,
                  isDaysWorkProject: !!(
                    projectDetail?.project_details?.project_type === 'Days Work'
                  ),
                })
              }}
              isDisabled={
                projectTicketDetails?.ticket_details?.status === 'completed'
              }
            />
          </div>
        </div>
      </div>
      <SetPlanPositionModel
        setModelOpen={setModelVisible}
        isModelOpen={isSetPlanModel}
        floorPlanId={ticketData?.floorPlanId?.id}
        ticketStatus={
          (projectTicketDetails?.ticket_details?.status ?? 'open') as
            | 'open'
            | 'in progress'
            | 'feedback'
            | 'completed'
        }
        edit={edit}
        planCallback={() => {
          setModelVisible(false)
          planCallback()
        }}
      />

      <AddUsersFromGroup
        isModalVisible={addUserModal}
        setModalVisible={setAddUserModal}
        attachUserList={setValue}
        assignedUserList={ticketData?.assignedUserIds}
      />
      <HandleLabourElementModal
        ticketId={projectTicketId}
        setModalVisible={setLabourElementModal}
        isModalVisible={isLabourElementModal}
        edit={edit}
        lineItemLabourElementList={
          projectDetail?.project_details?.project_type === 'Days Work'
            ? !!labourElementsOfItems?.fromLabourElementId
              ? labourElementsOfItems?.fromLabourElementId
              : labourElementsOfItems?.fromLabourElementGroupId
            : lineItemLabourElementList
        }
        selectedQuoteLineItem={ticketData?.selectedLineItem}
        isSubTicketEdit={isSubTicketEdit}
        isSubTicketModal={isSubTicketModal}
        selectedLabourElements={
          parentTicketDetailsFromRedux?.selectedLabourElements
        }
        onCloseModal={() => {
          onCloseModal()
          setIsModelVisible(false)
        }}
      />
      <FeedbackModal
        key={'Feedback Modal'}
        modalVisible={feedbackModalVisible}
        setModalVisible={setFeedbackModal}
        ticketId={projectTicketId}
        getTicketLogList={getTicketLogList}
        fetchTicketDetailsCB={fetchTicketDetails}
      />
    </Modal>
  )
}

interface JournalTextareaProps {
  onChangeJournalComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onCreateTicketComment: (cb: () => void) => void
  ticketComment: string
}

const JournalTextarea = ({
  onChangeJournalComment,
  onCreateTicketComment,
  ticketComment,
}: JournalTextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  if (textareaRef.current) {
    const newHeight = `${textareaRef.current.scrollHeight}px`

    if (textareaRef.current.scrollHeight <= 98)
      textareaRef.current.style.height = newHeight
  }

  return (
    <div className="flex border border-1 overflow-hidden bg-white border-solid border-gray-300 rounded-sm items-center absolute bottom-12 w-[95%] ">
      <textarea
        ref={textareaRef}
        value={ticketComment}
        className={`p-4 px-6 w-[100%] outline-none resize-none h-[32px]`}
        name="comment"
        placeholder="New comment"
        rows={0}
        onChange={(e) => onChangeJournalComment(e)}
      />

      <span
        className="cursor-pointer mr-4"
        onClick={() => {
          onCreateTicketComment(() => {
            textareaRef.current.style.height = '32px'
          })
        }}
      >
        <BsFillSendFill
          size={18}
          className="hover:text-blue-300  transition-all duration-200 ease-in-out"
        />
      </span>
    </div>
  )
}
