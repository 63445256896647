import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {BiError} from 'react-icons/bi'
import {MdEdit, MdWarning} from 'react-icons/md'
import {useAuth, useNavigation, useQuery} from 'react-auth-navigation'
import {useDispatch, useSelector} from 'react-redux'
import {useFormInput} from 'use-form-input'
import {TbDragDrop} from 'react-icons/tb'
import {HiArrowLeft} from 'react-icons/hi'

import {Button, CustomModal, Loading, toast} from 'app/common'
import {CustomGridItem, GridLayout} from 'app/components'

import {Heading} from '../heading'
import {Paragraph} from '../paragraph'
import {Section} from '../section'
import {Hyperlink} from '../hyperlink'
import {DateInput} from '../dateInput'
import {TimeInput} from '../timeInput'
import {NumberInput} from '../numberInput'
import {LongText, ShortText} from '../textField'
import {BlankSpace, Divider, PageBreak} from '../divider'
import {SingleChoiceOptions} from '../singleChoice'
import {MultipleChoiceOptions} from '../multipleChoice'
import {FormSidePanel} from '../formsSidePanel/formsSidePanel.component'
import {ImageComp, Photo, Signature, Video} from '../image'

import {
  HeadingPreview,
  HyperlinkPreview,
  DateInputPreview,
  NumberInputPreview,
  TimeInputPreview,
  ParagraphPreview,
  SectionPreview,
  LongTextPreview,
  ShortTextPreview,
  SingleChoicePreview,
  MultipleChoicePreview,
  PhotoPreview,
  SignaturePreview,
  VideoPreview,
  ImagePreview,
  ProjectUserPreview,
  ProjectStatusPreview,
  ProgressBarPreview,
} from '../../pages/formBuilderCreate/previewComponents'

import {
  createFormAction,
  getFormByIdAction,
  getFormFieldDataType,
  handleDroppedFormLayouts,
  publishFormTemplateAction,
  removeFormAssets,
  updateFormFieldAction,
  updateFormFromApi,
  updateFormLayouts,
} from 'redux-src'
import {sortArrayBasedOnXY} from 'utils'
import {useIndividualFormElement} from 'hooks'
import {ProjectUser} from '../projectUser'
import {ProjectStatus} from '../projectStatus'
import {ProgressBar} from '../progressBar'

export const FormContext = createContext<{
  selectedFormElementId: number
  isPreview: boolean
  removeSelectedElement: () => void
  selectedElement?: RT.FormElementType
  changeSelectedFormElementId: (
    id: number,
    formElement?: RT.FormElementType,
  ) => void
  draggedElement?: RT.FormElementType
  changeDraggedElement: (formElement?: RT.FormElementType) => void
}>({
  selectedElement: undefined,
  selectedFormElementId: 0,
  removeSelectedElement: () => {},
  isPreview: false,
  changeSelectedFormElementId: () => {},
  draggedElement: undefined,
  changeDraggedElement: () => {},
})

export const FormBuilder = ({edit}: {edit?: boolean}) => {
  const {
    params,
    navigation: {navigate, routes},
  } = useNavigation()

  const query = useQuery()

  const {formId} = params as any
  const {preview} = query as any

  const {
    formByIdData,
    createFormLoading,
    updateFormFieldLoading,
    formList,
    formByIdLoading,
  }: RT.FormsReduxType = useSelector((state: any) => state.forms)

  const [previewTabs, setPreviewTabs] = useState<
    Array<{id: number; title: string; isActive: boolean}>
  >([
    {id: 1, title: 'DESKTOP', isActive: false},
    {id: 2, title: 'MOBILE', isActive: false},
  ])

  const undoChanges = () => {
    dispatch(updateFormFromApi(formByIdData))
  }

  const {
    forms,
    formsForDesktopPreview,
    formName,
    updatedFieldIds,
    deletedFieldIds,
    addedFieldIds,
    layouts,
    isFormAltered,
    deletedAssetIds,
  }: RT.FormBuilderReduxType = useSelector((state: any) => state.formBuilder)

  const [selectedFormElementId, setSelectedFormElementId] = useState<number>(0)
  const [selectedElement, setSelectedElement] = useState<
    RT.FormElementType | undefined
  >()
  const [draggedElement, setDraggedElement] = useState<
    RT.FormElementType | undefined
  >()
  const [isPreview, setIsPreview] = useState<boolean>()

  const dispatch = useDispatch()

  const [
    formTemplateData,
    {onChange, onSubmit, errors, modified, setValue, isValid},
  ] = useFormInput(
    {
      formTemplateTitle: '',
    },
    (data) => {
      let errors: any = {}

      if (data?.formTemplateTitle.length === 0) {
        errors.formTemplateTitle = 'Form template title can not be empty !!'
      }

      return errors
    },
  )

  useEffect(() => {
    edit && formName && setValue('formTemplateTitle', formName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, formName])

  useEffect(() => {
    preview && setIsPreview(preview === 'true')
  }, [preview])

  const onCreateFormTemplate = (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    cb?: (id: number) => void,
    isPreview?: boolean,
  ) => {
    const sortedForms = sortArrayBasedOnXY(forms)?.map((form, index) => ({
      ...form,
      position: index + 1,
    }))

    // const sortedFormsConsole = sortedForms.map((form) => ({
    //   id: form.id,
    //   formType: form.formType,
    //   name: form.content?.label,
    //   position: form.position,
    //   x: form.layout.x,
    //   y: form.layout.y
    // }))

    // console.table(sortedFormsConsole)

    // return

    if (!formTemplateData?.formTemplateTitle) {
      return toast.error('Set the name for the template first !!')
    }

    const imageFieldsWithoutValue = sortedForms?.filter(
      (form) => form.formType === 'image' && !!!form.asset_details,
    )
    const headingFieldWithoutValue = sortedForms?.filter(
      (form) =>
        form?.formType === 'heading' && form?.content?.label?.length === 0,
    )
    const hyperlinkFieldWithoutValue = sortedForms?.filter(
      (form) =>
        form?.formType === 'hyperlink' && form?.content?.label?.length === 0,
    )
    const paragraphFieldWithoutValue = sortedForms?.filter(
      (form) =>
        form?.formType === 'paragraph' && form?.content?.label?.length === 0,
    )
    const sectionFieldWithoutValue = sortedForms?.filter(
      (form) =>
        form?.formType === 'section' && form?.content?.label?.length === 0,
    )

    if (imageFieldsWithoutValue?.length > 0) {
      return toast.error(
        'Image for the instructional element is mandatory. Please upload an image for each Image element.',
      )
    }

    if (headingFieldWithoutValue?.length > 0) {
      return toast.error('Heading for the instructional element is mandatory.')
    }
    if (hyperlinkFieldWithoutValue?.length > 0) {
      return toast.error(
        'Hyperlink for the instructional element is mandatory.',
      )
    }
    if (paragraphFieldWithoutValue?.length > 0) {
      return toast.error(
        'Paragraph for the instructional element is mandatory.',
      )
    }
    if (sectionFieldWithoutValue?.length > 0) {
      return toast.error('Section for the instructional element is mandatory.')
    }

    onSubmit(e)

    if (!isValid(errors)) {
      return
    }

    if (edit) {
      const editedForm = formList?.find((form) => form.id === formId)?.status

      if (
        editedForm === 'published' &&
        updatedFieldIds.length === 0 &&
        addedFieldIds.length === 0 &&
        deletedFieldIds.length === 0
      ) {
        cb?.(formId)
        return toast.warning('No changes made to the form!')
      }

      const updatedFields = updatedFieldIds
        .map((id) => sortedForms?.find((form) => id === form.id))
        ?.filter((form) => form)
        ?.map((form) => {
          return {
            dataTypeId: form.dataTypeId,
            position: form.position,
            editableBy: form.editableBy,
            visibleTo: form.visibleTo,
            properties: form,
            id: form.id,
            assetId: form.asset_details?.id,
          }
        })

      const addedFields = addedFieldIds
        .map((id) => sortedForms?.find((form) => id === form.id))
        ?.filter((form) => form)
        ?.map((form) => {
          return {
            dataTypeId: form.dataTypeId,
            position: form.position,
            editableBy: form.editableBy,
            visibleTo: form.visibleTo,
            properties: form,
            assetId: form.asset_details?.id,
          }
        })

      const deletedFields = deletedFieldIds
        .map((id) => {
          const deletedForm = formByIdData?.fields.find(
            (form) => id === form.id,
          )

          if (deletedForm) {
            return {id: deletedForm.id}
          } else {
            return undefined
          }
        })
        ?.filter((form) => form)

      dispatch(
        updateFormFieldAction(
          formId,
          {updatedFields, deletedFields, addedFields},
          () => {
            if (deletedAssetIds.length > 0) {
              dispatch(
                removeFormAssets(
                  {
                    assetIds: deletedAssetIds,
                  },
                  () => {
                    dispatch(getFormFieldDataType())
                    dispatch(
                      updateFormFromApi(undefined, () => {
                        dispatch(getFormByIdAction(formId))
                      }),
                    )
                  },
                ),
              )
            } else {
              dispatch(getFormFieldDataType())
              dispatch(
                updateFormFromApi(undefined, () => {
                  dispatch(getFormByIdAction(formId))
                }),
              )
            }
            cb?.(formId)
          },
        ),
      )
      return
    }

    const formTemplateFields = sortedForms?.map((form) => {
      return {
        dataTypeId: form.dataTypeId,
        position: form.position,
        editableBy: form.editableBy,
        visibleTo: form.visibleTo,
        properties: form,
        assetId: form.asset_details?.id,
      }
    })

    const formTemplateBody = {
      formName: formTemplateData?.formTemplateTitle,
      fields: formTemplateFields,
    }

    dispatch(
      createFormAction(formTemplateBody, (id) => {
        if (deletedAssetIds.length > 0) {
          dispatch(
            removeFormAssets(
              {
                assetIds: deletedAssetIds,
              },
              () => {
                dispatch(
                  updateFormFromApi(undefined, () => {
                    navigate(
                      `/settings/forms/edit/${id}${
                        isPreview ? '?preview=true' : ''
                      }`,
                    )
                    cb?.(id)
                  }),
                )
              },
            ),
          )
        } else {
          dispatch(
            updateFormFromApi(undefined, () => {
              navigate(
                `/settings/forms/edit/${id}${isPreview ? '?preview=true' : ''}`,
              )

              cb?.(id)
            }),
          )
        }

        // cb?.(id)
      }),
    )
  }

  const publishFormTemplate = (e: React.MouseEvent<HTMLButtonElement>) => {
    onCreateFormTemplate(e, (id) =>
      dispatch(
        publishFormTemplateAction(id, () => {
          dispatch(getFormByIdAction(id))
        }),
      ),
    )
  }

  useEffect(() => {
    dispatch(getFormFieldDataType())

    return () => {
      dispatch(updateFormFromApi(undefined))
    }
  }, [dispatch])

  const changeSelectedFormElementId = useCallback(
    (id, formElement) => {
      const formFromFormsState = forms.find((form) => form.id === id)
      if (id !== selectedFormElementId) {
        setSelectedFormElementId(id)
        setSelectedElement(formElement ?? formFromFormsState)
      } else setSelectedElement(formFromFormsState)
    },
    [forms, selectedFormElementId],
  )

  const handleUnsavedChanges = (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    cb?: () => void,
    isPreview?: boolean,
  ) => {
    onCreateFormTemplate(e, cb, isPreview)
  }

  useEffect(() => {
    isPreview &&
      setPreviewTabs((prev) =>
        prev.map((itm) => {
          if (itm.id === 1) {
            return {...itm, isActive: true}
          } else {
            return {...itm, isActive: false}
          }
        }),
      )
  }, [isPreview])

  return createFormLoading || updateFormFieldLoading || formByIdLoading ? (
    <Loading />
  ) : (
    <FormContext.Provider
      value={{
        selectedElement,
        selectedFormElementId,
        isPreview,
        removeSelectedElement: () => {
          setSelectedElement(undefined)
          setSelectedFormElementId(0)
        },
        changeSelectedFormElementId,
        draggedElement,
        changeDraggedElement: (el) => {
          setDraggedElement(el)
        },
      }}
    >
      <div className="flex flex-col gap-10 relative">
        {isFormAltered ? (
          <SaveConfirmationModal
            handleUnsavedChanges={handleUnsavedChanges}
            triggerElement={
              <div className="flex items-center justify-between">
                <h2
                  className="text-sm flex items-centerw-[150px] mb-10 cursor-pointer"
                  style={{color: 'blue'}}
                >
                  <span className="mr-10 mt-[3px]">
                    <HiArrowLeft />
                  </span>
                  Back to forms
                </h2>
              </div>
            }
            cb={() => {
              navigate(routes['Form Main Page'].path)
            }}
          />
        ) : (
          <div className="flex items-center justify-between">
            <h2
              className="text-sm flex items-centerw-[150px] mb-10 cursor-pointer"
              style={{color: 'blue'}}
              onClick={() => navigate(routes['Form Main Page'].path)}
            >
              <span className="mr-10 mt-[3px]">
                <HiArrowLeft />
              </span>
              Back to forms
            </h2>
          </div>
        )}
        <div className="w-full flex justify-between items-center border border-b-1 border-solid border-gray-200">
          <div className="w-[32%] flex py-14 mt-10">
            <div className="w-[100%] flex flex-col">
              <input
                type="text"
                name="formTemplateTitle"
                placeholder="Set a name for the template"
                className="text-lg w-full items-center"
                value={formTemplateData?.formTemplateTitle}
                onChange={onChange}
              />
              {errors?.formTemplateTitle && modified?.formTemplateTitle && (
                <div className="text-[10px] text-red-300 mt-6">
                  {errors?.formTemplateTitle}
                </div>
              )}
            </div>
            <MdEdit size={22} />
          </div>
          <div className="flex gap-12">
            <div className="flex flex-row flex-nowrap gap-0">
              <button
                className={`h-[30px] border border-solid border-1 border-gray-300 rounded-l-sm px-30 text-sm text-black ${
                  !isPreview ? 'bg-gray-300 text-white' : ''
                }`}
                onClick={() => {
                  setIsPreview(false)
                }}
              >
                Edit
              </button>
              {isFormAltered ? (
                <SaveConfirmationModal
                  handleUnsavedChanges={(e, cb) =>
                    handleUnsavedChanges(e, cb, true)
                  }
                  triggerElement={
                    <button
                      className={`h-[30px] border border-solid border-1 border-gray-300 px-30 rounded-r-sm text-sm text-black ${
                        isPreview ? 'bg-gray-300 text-white' : ''
                      }`}
                      disabled={
                        forms?.length > 0 &&
                        formTemplateData?.formTemplateTitle.length !== 0
                          ? false
                          : true
                      }
                    >
                      Preview
                    </button>
                  }
                  cb={() => {
                    setIsPreview(true)
                  }}
                />
              ) : (
                <button
                  className={`h-[30px] border border-solid border-1 border-gray-300 px-30 rounded-r-sm text-sm text-black ${
                    isPreview ? 'bg-gray-300 text-white' : ''
                  }`}
                  onClick={() => {
                    setIsPreview(true)
                  }}
                  disabled={
                    forms?.length > 0 &&
                    formTemplateData?.formTemplateTitle.length !== 0
                      ? false
                      : true
                  }
                >
                  Preview
                </button>
              )}
            </div>
            <div>
              <Button
                className={'h-[30px]'}
                isDisabled={
                  forms?.length > 0 &&
                  formTemplateData?.formTemplateTitle.length !== 0
                    ? false
                    : true
                }
                onClick={publishFormTemplate}
              >
                Publish
              </Button>
            </div>
          </div>
        </div>
        {layouts && layouts.length === 0 && edit ? (
          <Loading />
        ) : !isPreview ? (
          <>
            <div className="flex">
              <div className="flex flex-1 flex-col gap-8 pr-20">
                <div className="text-lg font-bold mb-8 text-gray-600">
                  {formTemplateData?.formTemplateTitle}
                </div>
                <div className="flex flex-1 flex-col gap-8 relative">
                  <GridLayout
                    layout={layouts}
                    droppingItem={{
                      h: draggedElement?.layout.h ?? 6,
                      w: draggedElement?.layout.w ?? 6,
                    }}
                    onLayoutChange={(layout) => {
                      !!!draggedElement && dispatch(updateFormLayouts(layout))
                    }}
                    onDrop={(layout, item) => {
                      draggedElement &&
                        dispatch(
                          handleDroppedFormLayouts(
                            layout,
                            {
                              ...draggedElement,
                              layout: {
                                ...draggedElement.layout,
                                x: item.x,
                                y: item.y,
                              },
                            },
                            (id, formEl) => {
                              setDraggedElement(undefined)
                              changeSelectedFormElementId(id, {...formEl, id})
                            },
                          ),
                        )
                    }}
                    onDragStart={(layout, item) => {
                      const currItem = forms?.find(
                        (formItem) => +item.i === formItem.id,
                      )
                      changeSelectedFormElementId(currItem?.id, {
                        ...currItem,
                        id: currItem?.id,
                      })
                    }}
                    isDraggable
                    isDroppable
                  >
                    {forms?.length > 0 &&
                      forms?.map((form) => {
                        return (
                          <CustomGridItem
                            key={form.id}
                            data-grid={form?.layout}
                            isDraggableElement
                          >
                            <FormSection
                              key={form?.id}
                              formType={form?.formType}
                              id={form?.id}
                            />
                          </CustomGridItem>
                        )
                      })}
                  </GridLayout>
                  {forms?.length === 0 && (
                    <div className="flex select-none pointer-events-none flex-col gap-10 items-center justify-center absolute translate-y-half translate-x-half left-[20%] top-[20%]">
                      <span>
                        <TbDragDrop size={70} className="text-blue-300" />
                      </span>
                      <span className="text-md text-gray-400">
                        Drag & drop an element here
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex w-[250px] border border-l-1 border-gray-200">
                <FormSidePanel />
              </div>
            </div>

            <div className="flex justify-between sticky bottom-0 py-20 border-t-1 border-gray-200 bg-white ">
              <div className="flex gap-10 items-center">
                <span className="text-yellow-300">
                  <BiError fontSize={14} />
                </span>
                <span className="text-gray-400">
                  Don't forget to save your changes
                </span>
              </div>
              <div className="flex flex-row justify-center items-center">
                <span
                  className="text-blue-300 cursor-pointer mr-10 capitalize"
                  onClick={undoChanges}
                >
                  undo changes
                </span>
                <Button
                  title="Save"
                  onClick={(e) => {
                    onCreateFormTemplate(e)
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="w-full flex flex-col gap-10">
            <div className="flex items-center justify-center">
              {previewTabs?.map((item, index: number) => {
                return (
                  <div
                    key={item?.id}
                    className={`w-[100px] text-center cursor-pointer  py-6 ${
                      index === 0 ? 'rounded-l-sm' : 'rounded-r-sm'
                    } ${
                      item?.isActive
                        ? 'border-1 border-blue-300 bg-blue-300'
                        : 'border-1 border-gray-200 text-black'
                    }`}
                    onClick={() => {
                      setPreviewTabs((prev) =>
                        prev.map((itm) => {
                          if (itm.id === item.id) {
                            return {...itm, isActive: true}
                          } else {
                            return {...itm, isActive: false}
                          }
                        }),
                      )
                    }}
                  >
                    <span
                      className={` font-bold text-md ${
                        item?.isActive ? 'text-white' : 'text-gray-400'
                      }`}
                    >
                      {item?.title}
                    </span>
                  </div>
                )
              })}
            </div>
            {previewTabs.at(0).isActive && (
              <div className="flex flex-1 flex-col gap-8 pr-20">
                <div className="text-lg font-bold mb-8 mt-10 text-gray-600">
                  {formTemplateData?.formTemplateTitle}
                </div>
                <div className="flex flex-col flex-1 gap-8 mb-20">
                  {formsForDesktopPreview?.length > 0 &&
                    formsForDesktopPreview?.map((fm, index) => {
                      return (
                        <div
                          key={`form-preview-item-container-${index}`}
                          id={`form-preview-item-container-${index}`}
                          className={`flex flex-row gap-8 w-full`}
                        >
                          {fm?.map((form) => {
                            return (
                              <FormSectionPreview
                                key={form.id}
                                formType={form?.formType}
                                id={form?.id}
                                type={'preview'}
                              />
                            )
                          })}
                        </div>
                      )
                    })}
                </div>
              </div>
            )}
            {previewTabs.at(1).isActive && (
              <div className="flex w-full items-center justify-center mt-10">
                <div className="relative w-[400px] h-[70vh] px-12 py-[60px] border-1 border-gray-400 rounded-[20px] bg-[#333]">
                  <div className="absolute  h-[12px] w-[100px] rounded-[10px] top-10 right-[0] left-[0] m-auto bg-[white]"></div>
                  <div className="absolute  h-[12px] w-[12px] rounded-[100%] top-[34px] right-[0] left-[0] m-auto bg-[white]"></div>

                  <div className="border-1 border-gray-400 h-full bg-white overflow-y-scroll p-14">
                    <div className="flex flex-1 flex-col gap-8">
                      <div className="text-lg font-bold mb-8 mt-10 text-gray-600">
                        {formTemplateData?.formTemplateTitle}
                      </div>
                      <div className="flex flex-1 flex-col gap-8 mb-20">
                        {forms?.length > 0 &&
                          forms?.map((form) => {
                            return (
                              <FormSectionPreview
                                key={form.id}
                                formType={form?.formType}
                                id={form?.id}
                                // type={'web view'}
                                isFieldsEditable={false}
                              />
                            )
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="absolute  h-[18px] w-[70px] rounded-[10px] bottom-14 right-[0] left-[0] m-auto bg-[white]"></div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </FormContext.Provider>
  )
}

const FormSection = ({
  formType,
  id,
}: {
  formType: RT.FormBuilderType
  id: number
}) => {
  const {changeSelectedFormElementId: onSelectFormSection} =
    useContext(FormContext)

  switch (formType) {
    case 'heading':
      return <Heading id={id} onSelectElement={onSelectFormSection} />
    case 'hyperlink':
      return <Hyperlink id={id} onSelectElement={onSelectFormSection} />
    case 'date input':
      return <DateInput id={id} onSelectElement={onSelectFormSection} />
    case 'paragraph':
      return <Paragraph id={id} onSelectElement={onSelectFormSection} />
    case 'section':
      return <Section id={id} onSelectElement={onSelectFormSection} />
    case 'long text':
      return <LongText id={id} onSelectElement={onSelectFormSection} />
    case 'short text':
      return <ShortText id={id} onSelectElement={onSelectFormSection} />
    case 'number input':
      return <NumberInput id={id} onSelectElement={onSelectFormSection} />
    case 'time input':
      return <TimeInput id={id} onSelectElement={onSelectFormSection} />
    case 'divider':
      return <Divider id={id} onSelectElement={onSelectFormSection} />
    case 'page break':
      return <PageBreak id={id} onSelectElement={onSelectFormSection} />
    case 'blank space':
      return <BlankSpace id={id} onSelectElement={onSelectFormSection} />
    case 'single choice':
      return (
        <SingleChoiceOptions id={id} onSelectElement={onSelectFormSection} />
      )
    case 'multiple choice':
      return (
        <MultipleChoiceOptions id={id} onSelectElement={onSelectFormSection} />
      )
    case 'image':
      return <ImageComp id={id} onSelectElement={onSelectFormSection} />
    case 'photo':
      return <Photo id={id} onSelectElement={onSelectFormSection} />
    case 'signature':
      return <Signature id={id} onSelectElement={onSelectFormSection} />
    case 'video':
      return <Video id={id} onSelectElement={onSelectFormSection} />
    case 'project user':
      return <ProjectUser id={id} onSelectElement={onSelectFormSection} />
    case 'project status':
      return <ProjectStatus id={id} onSelectElement={onSelectFormSection} />
    case 'progressbar':
      return <ProgressBar id={id} onSelectElement={onSelectFormSection} />
    default:
      return <div></div>
  }
}

export const FormSectionPreview = ({
  formType,
  id,
  type = 'builder',
  isFieldsEditable = false,
}: {
  formType: RT.FormBuilderType
  id: number
  type?: 'builder' | 'preview' | 'web view'
  isFieldsEditable?: boolean
}) => {
  switch (formType) {
    case 'heading':
      return <HeadingPreview id={id} type={type} />
    case 'hyperlink':
      return <HyperlinkPreview id={id} type={type} />
    case 'date input':
      return (
        <DateInputPreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    case 'paragraph':
      return <ParagraphPreview id={id} type={type} />
    case 'section':
      return <SectionPreview id={id} type={type} />
    case 'long text':
      return (
        <LongTextPreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    case 'short text':
      return (
        <ShortTextPreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    case 'number input':
      return (
        <NumberInputPreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    case 'time input':
      return (
        <TimeInputPreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    case 'divider':
      return <DividerPreview id={id} type={type} />
    case 'page break':
      return <PageBreakPreview id={id} type={type} />
    case 'blank space':
      return <BlankSpacePreview id={id} type={type} />
    case 'single choice':
      return (
        <SingleChoicePreview
          id={id}
          type={type}
          isEditable={isFieldsEditable}
        />
      )
    case 'multiple choice':
      return (
        <MultipleChoicePreview
          id={id}
          type={type}
          isEditable={isFieldsEditable}
        />
      )
    case 'image':
      return <ImagePreview id={id} type={type} />
    case 'photo':
      return <PhotoPreview id={id} type={type} isEditable={isFieldsEditable} />
    case 'signature':
      return (
        <SignaturePreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    case 'video':
      return <VideoPreview id={id} type={type} isEditable={isFieldsEditable} />
    case 'project user':
      return (
        <ProjectUserPreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    case 'project status':
      return (
        <ProjectStatusPreview
          id={id}
          type={type}
          isEditable={isFieldsEditable}
        />
      )
    case 'progressbar':
      return (
        <ProgressBarPreview id={id} type={type} isEditable={isFieldsEditable} />
      )
    default:
      return <div></div>
  }
}

const SaveConfirmationModal = ({
  triggerElement,
  handleUnsavedChanges,
  cb,
}: {
  triggerElement: React.ReactNode
  handleUnsavedChanges: (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    cb?: () => void,
  ) => void
  cb?: () => void
}) => (
  <CustomModal
    displayElement={triggerElement}
    title={'Save your changes'}
    withPortal={true}
    size={'sm'}
  >
    {({onCloseModalHandler}) => (
      <div className="flex flex-col py-10 my-10 px-14 items-center justify-center">
        <div className="text-center mx-40 my-20">
          You've made some changes to this page. Changes you made will be
          ignored without saving.
        </div>

        <div className="text-center mx-40 mt-20 mb-10">
          Would you like to save those changes?
        </div>

        <div className="flex flex-col justify-start items-center text-xs text-gray-400 text-center  mt-10 mb-20">
          <div className="flex gap-6">
            <MdWarning size={14} color={'#f0f000'} />
            Saving the form will make it a draft!
          </div>
          Publish the form to assign in projects and tickets.
        </div>

        <Button
          onClick={(e) =>
            handleUnsavedChanges(e, () => {
              cb?.()
              onCloseModalHandler()
            })
          }
          size="sm"
        >
          Save & Continue
        </Button>
        <div
          className="text-blue-300 cursor-pointer my-6  mb-20 "
          onClick={() => {
            cb?.()
            onCloseModalHandler()
          }}
        >
          Continue without saving
        </div>
      </div>
    )}
  </CustomModal>
)

const DividerPreview = ({
  id,
  type = 'builder',
}: {
  id: number
  type: 'builder' | 'preview' | 'web view'
}) => {
  const {userRole} = useAuth()

  const {individualStyles, individualElement} = useIndividualFormElement(
    id,
    type,
  )

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div
      className={`my-10 bg-gray-200 h-[1px] ${individualStyles.replace(
        'flex-1',
        '',
      )}`}
    />
  ) : (
    <></>
  )
}

const BlankSpacePreview = ({
  id,
  type = 'builder',
}: {
  id: number
  type: 'builder' | 'preview' | 'web view'
}) => {
  const {userRole} = useAuth()

  const {individualStyles, individualElement} = useIndividualFormElement(
    id,
    type,
  )

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div
      className={`my-16  h-[10px] ${individualStyles.replace('flex-1', '')}`}
    />
  ) : (
    <></>
  )
}

const PageBreakPreview = ({
  id,
  type,
}: {
  id: number
  type: 'builder' | 'preview' | 'web view'
}) => {
  const {userRole} = useAuth()

  const {individualElement} = useIndividualFormElement(id, type)

  return individualElement?.visibleTo?.includes(userRole) ? (
    <div className="h-[1px] w-full mt-[10dvh]"></div>
  ) : (
    <></>
  )
}
